const translations = {
  "Cost per Click": "Cost per Click",
  Budget: "Budget",
  "title.SETTINGS_USER_PROFILE": "User Profile",
  // Manage: "Manage_EN",
  // "Manage Billing": "Manage Billing_EN",
  // Balance: "Balance_EN",
  // "Promotional Balance": "Promotional Balance_EN",
  // "Max usage allowed": "Max usage allowed_EN",
  // "The net remaining funds designated for ad spends dedicated to the selected campaign type.":
  //   "The net remaining funds designated for ad spends dedicated to the selected campaign type._EN",
  // "The net available amount, attached to all wallets designated for ad spends dedicated to the selected campaign type.":
  //   "The net available amount, attached to all wallets designated for ad spends dedicated to the selected campaign type._EN",
  // "The maximum net amount permitted for expenditure as ad spends for the selected campaign type.":
  //   "The maximum net amount permitted for expenditure as ad spends for the selected campaign type._EN",
  // "The remaining funds designated for ad spends within the default or selected wallet.":
  //   "The remaining funds designated for ad spends within the default or selected wallet._EN",
  // "The available amount, attached with the default or selected wallet, specifically allocated for incentivizing eligible campaign types.":
  //   "The available amount, attached with the default or selected wallet, specifically allocated for incentivizing eligible campaign types._EN",
  // "The maximum net amount permitted for expenditure as ad spends within the default or selected wallet.":
  //   "The maximum net amount permitted for expenditure as ad spends within the default or selected wallet._EN",
  // "Wallet Details": "Wallet Details_EN",
  // "Minimum Top-up Amount": "Minimum Top-up Amount_EN",
  // "Add Money": "Add Money_EN",
  // "Create New Wallet": "Create New Wallet_EN",
  // "+ Create New Wallet": "+ Create New Wallet_EN",
  // "Create Wallet": "Create Wallet_EN",
  // "Wallet Name": "Wallet Name_EN",
  // "Campaign Type": "Campaign Type_EN",
  // "Enter Wallet Name": "Enter Wallet Name_EN",
  // "<0>Select Campaign Type</0>": "<0>Select Campaign Type_EN</0>",
  // "Please enter wallet name": "Please enter wallet name_EN",
  // "Please select campaign Type": "Please select campaign Type_EN",
  // All: "All_EN",
  // Date: "Date_EN",
  // "Transaction Log": "Transaction Log_EN",
  // "Add Filter": "Add Filter_EN",
  // "Add/Remove Columns": "Add/Remove Columns_EN",
  // "Select a date": "Select a date_EN",
  // "less than (<)": "less than (<)_EN",
  // "less than or equals (<=)": "less than or equals (<=)_EN",
  // "greater than (>)": "greater than (>)_EN",
  // "greater than or equals (>=)": "greater than or equals (>=)_EN",
  // "<0>Select an options</0>": "<0>Select an options_EN</0>",
  // "has any of": "has any of_EN",
  // "has none of": "has none of_EN",
  // "equals (=)": "equals (=)_EN",
  // "not equals (≠)": "not equals (≠)_EN",
  // "Enter a value": "Enter a value_EN",
  // "does not contains": "does not contains_EN",
  // Search: "Search_EN",
  // Spend: "Spend_EN",
  // "(last 7 days)": "(last 7 days)_EN",
  // "Promotion Balance": "Promotion Balance_EN",
  // "Campaign Type Tagged": "Campaign Type Tagged_EN",
  // "Active Campaigns": "Active Campaigns_EN",
  // "Manage Wallet": "Manage Wallet_EN",
  // From: "From_EN",
  // To: "To_EN",
  // "Enter Amount": "Enter Amount_EN",
  // "Please select wallet": "Please select wallet_EN",
  // "Please enter amount": "Please enter amount_EN",
  // "Please recharge your wallet for transfer": "Please recharge your wallet for transfer_EN",
  // "Enter Description": "Enter Description_EN",
  // "Transferred from {{from}} to {{to}}": "Transferred from {{from}} to {{to}}_EN",
  // "<0>Select To</0>": "<0>Select To_EN</0>",
  // "<0>Select From</0>": "<0>Select From_EN</0>",
  // "<0>Wallet Balance</0>": "<0>Wallet Balance_EN</0>",
  // "<0>Amount should be less than</0>": "<0>Amount should be less than_EN</0>",
  // "<0>Maximum Transfer Amount:</0>": "<0>Maximum Transfer Amount:_EN</0>",
  // "Transfer": "Transfer_EN",
  // "Amount Transferred successfully.": "Amount Transferred successfully._EN",
  // "Transaction Log for {{walletName}}": "Transaction Log for {{walletName}}_EN",
  // " (Excluding **{{percentage}}%** {{taxName}})": " (Excluding **{{percentage}}%** {{taxName}})_EN",
  // "<0>Minimum Top-up Amount</0>": "<0>Minimum Top-up Amount_EN</0>",
  // "Wallet created successfully": "Wallet created successfully_EN",
  // "Wallet updated successfully": "Wallet updated successfully_EN",
  // "Add Money to Your Wallet": "Add Money to Your Wallet_EN",
  // "Refresh": "Refresh_EN",
  // "Wallet": "Wallet_EN",
  // "You’re unable to link this wallet to the campaign because it is not eligible for {{type}} format.": "You’re unable to link this wallet to the campaign because it is not eligible for {{type}} format.",
  // "Please be aware that if your wallet balance displays a negative value, it indicates overspending. This situation may arise if multiple users' activities are attributed to your ad simultaneously, especially when your wallet balance is close to exhaustion.": "Please be aware that if your wallet balance displays a negative value, it indicates overspending. This situation may arise if multiple users' activities are attributed to your ad simultaneously, especially when your wallet balance is close to exhaustion.",
  // "If we're unable to deliver the booked impressions, we'll reimburse the remaining balance back to your ad wallet.": "If we're unable to deliver the booked impressions, we'll reimburse the remaining balance back to your ad wallet.",
  "title.SETTINGS_USER_PROFILE": "User Profile",
  "Report Generated On:": "Report Generated On:",
  "Report Requested On:": "Report Requested On:",
  "Your report is delivered on your mail box": "Your report is delivered on your mail box",
  "You will receive detailed report in your mail box once ready":
    "You will receive detailed report in your mail box once ready",
  "Report Details for {{campaignName}}": "Report Details for {{campaignName}}",
  "Campaign is recently activated": "Campaign is recently activated",
  "Your campaign launched recently. Once your campaign starts serving, you’ll be able to troubleshoot any issues.":
    "Your campaign launched recently. Once your campaign starts serving, you’ll be able to troubleshoot any issues.",
  "Troubleshoot Report Generated in the last hour":
    "Troubleshoot Report Generated in the last hour",
  "You generated a troubleshoot report recently which is available for analysis. Please wait for a while before attempting troubleshooting again.":
    "You generated a troubleshoot report recently which is available for analysis. Please wait for a while before attempting troubleshooting again.",
  "Troubleshoot Report Available for Analysis": "Troubleshoot Report Available for Analysis",
  "You recently generated a troubleshoot report, which is now available for analysis. Would you like to review the report?":
    "You recently generated a troubleshoot report, which is now available for analysis. Would you like to review the report?",

  // Campaign Debugger Report
  "Campaign was recently created. Please try to troubleshoot after some time .":
    "Campaign was recently created. Please try to troubleshoot after some time .",
  "Your campaign is still in Draft status hence it’s not active.":
    "Your campaign is still in Draft status hence it’s not active.",
  "Your campaign has been paused, and there could be several reasons for this. Perhaps you manually paused the campaign, its end date has been reached, or the campaign has reached its spend cap / total budget.":
    "Your campaign has been paused, and there could be several reasons for this. Perhaps you manually paused the campaign, its end date has been reached, or the campaign has reached its spend cap / total budget.",
  "Your campaign is active and will start delivering ads soon.":
    "Your campaign is active and will start delivering ads soon.",
  "Your campaign has not yet reached its scheduled start date.":
    "Your campaign has not yet reached its scheduled start date.",
  "Your campaign has reached its end date.": "Your campaign has reached its end date.",
  "Your campaign is currently within its active date range.":
    "Your campaign is currently within its active date range.",
  "Your campaign is not getting impressions with respect to it’s potential.":
    "Your campaign is not getting impressions with respect to it’s potential.",
  "Your campaign is garnering a robust number of impressions, suggesting that your ads are effectively reaching potential audiences.":
    "Your campaign is garnering a robust number of impressions, suggesting that your ads are effectively reaching potential audiences.",
  "Your wallet balance is not sufficient to keep serving ads. Please top-up your wallet to avoid any interruptions.":
    "Your wallet balance is not sufficient to keep serving ads. Please top-up your wallet to avoid any interruptions.",
  "Your wallet balance is nearing the minimum threshold required to run the campaign. Consider adding funds to ensure uninterrupted ad delivery.":
    "Your wallet balance is nearing the minimum threshold required to run the campaign. Consider adding funds to ensure uninterrupted ad delivery.",
  "Your wallet balance is sufficient to continue running the campaign at the current spending rate.":
    "Your wallet balance is sufficient to continue running the campaign at the current spending rate.",
  "Your daily budget has been exhausted for the day. The campaign will stop serving ads until tomorrow unless the daily budget is increased.":
    "Your daily budget has been exhausted for the day. The campaign will stop serving ads until tomorrow unless the daily budget is increased.",
  "Your daily budget is nearing exhaustion. Consider increasing your budget to avoid any interruptions.":
    "Your daily budget is nearing exhaustion. Consider increasing your budget to avoid any interruptions.",
  "Your campaign has not exhausted its daily budget, leaving room for further spending for the day.":
    "Your campaign has not exhausted its daily budget, leaving room for further spending for the day.",
  "Your campaign bid falls below the required minimum CPM for certain inventories to participate in auction. This could potentially restrict your campaign's reach. To compete more efficiently, consider raising your bid.":
    "Your campaign bid falls below the required minimum CPM for certain inventories to participate in auction. This could potentially restrict your campaign's reach. To compete more efficiently, consider raising your bid.",
  "Your campaign bid is more than the minimum bid values required by all the ad inventories selected in your campaign.":
    "Your campaign bid is more than the minimum bid values required by all the ad inventories selected in your campaign.",
  "Your campaign has reached its maximum spend cap. The campaign will stop serving ads until you increase the spend cap on this campaign.":
    "Your campaign has reached its maximum spend cap. The campaign will stop serving ads until you increase the spend cap on this campaign.",
  "Your campaign is approaching its maximum spend cap. Consider monitoring the spend closely and adjusting the cap if necessary.":
    "Your campaign is approaching its maximum spend cap. Consider monitoring the spend closely and adjusting the cap if necessary.",
  "Your campaign has not exceeded its Maximum spend cap, leaving room for further spending for the day.":
    "Your campaign has not exceeded its Maximum spend cap, leaving room for further spending for the day.",
  "None of your campaign creatives are approved. Please check if there are rejections and adjust your creatives according to guidelines.":
    "None of your campaign creatives are approved. Please check if there are rejections and adjust your creatives according to guidelines.",
  "Some of your campaign creatives are not approved hence inactive in the campaign.":
    "Some of your campaign creatives are not approved hence inactive in the campaign.",
  "All your campaign creatives are approved and active.":
    "All your campaign creatives are approved and active.",
  "Your campaign didn't win in any of the chosen inventories. Consider increasing your bid, and if the situation persists raise a ticket to support for further investigation.":
    "Your campaign didn't win in any of the chosen inventories. Consider increasing your bid, and if the situation persists raise a ticket to support for further investigation.",
  "Your campaign didn't win in some of the chosen inventories. Consider increasing your bid in those inventories, and if the situation persists raise a ticket to support for further investigation.":
    "Your campaign didn't win in some of the chosen inventories. Consider increasing your bid in those inventories, and if the situation persists raise a ticket to support for further investigation.",
  "Your campaigns are performing in all of your chosen inventories.":
    "Your campaigns are performing in all of your chosen inventories.",
  "None of the keywords you've chosen for targeting have not been approved, and therefore, are inactive in the campaign.":
    "None of the keywords you've chosen for targeting have not been approved, and therefore, are inactive in the campaign.",
  "Some of the keywords you've chosen for targeting have not been approved, and therefore, are inactive in the campaign.":
    "Some of the keywords you've chosen for targeting have not been approved, and therefore, are inactive in the campaign.",
  "All your chosen keywords for targeting have been approved, and are active in the campaign.":
    "All your chosen keywords for targeting have been approved, and are active in the campaign.",
  // Capmpaign Debugger - Campaign Attributes Snapshot
  "Campaign Attributes Snapshot": "Campaign Attributes Snapshot",
  "Wallet Balance": "Wallet Balance",
  "Daily Budget": "Daily Budget",
  "CPM Bid": "CPM Bid",
  "Est. Impressions": "Est. Impressions",
  "Active Ad Creatives": "Active Ad Creatives",
  "Ad Inventories Count": "Ad Inventories Count",
  "Find Anything": "Find Anything",
  Orders: "Orders",
  "All Orders": "All Orders",
  "Review Orders": "Review Orders",
  "Product Templates": "Product Templates",
  "Campaign Templates": "Campaign Templates",
  "Control Center": "Control Center",
  "Ad Account Onboarding": "Ad Account Onboarding",
  "Download Sample File": "Download Sample File",
  "Upload .xlsx file with details": "Upload .xlsx file with details",
  "User Access Management": "User Access Management",
  "AdOps Users": "AdOps Users",
  "A admin user can add another admin, modify access for edit and read only":
    "A admin user can add another admin, modify access for edit and read only",
  "Add New User": "Add New User",
  Search: "Search",
  Name: "Name",
  Email: "Email",
  "Access Role": "Access Role",
  Read: "Read",
  Write: "Write",
  Admin: "Admin",
  Delete: "Delete",
  "Media Rep User": "Media Rep User",
  "Select ad account:": "Select ad account:",
  "Ad Account Manager": "Ad Account Manager",
  "Please select a Ad Account": "Please select a Ad Account",
  "Access Denied": "Access Denied",
  "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.":
    "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
  "Looks like we are facing some internal issue. Please, retry after sometime.":
    "Looks like we are facing some internal issue. Please, retry after sometime.",
  "Error in fetching data": "Error in fetching data",
  "Error while fetching data": "Error while fetching data",
  "Results failed to fetch, Please reload": "Results failed to fetch, Please reload",
  "We've Encountered a Internal Error": "We've Encountered a Internal Error",
  "We've reported this error. We are looking into it.":
    "We've reported this error. We are looking into it.",
  Settings: "Settings",
  Logout: "Logout",
  "You have been logged out from all the devices. Please try logging in again.":
    "You have been logged out from all the devices. Please try logging in again.",
  Alert: "Alert",
  Reload: "Reload",
  Password: "Password",
  Login: "Login",
  "Forgot Password?": "Forgot Password?",
  "Enter email address": "Enter email address",
  "Back to sign in": "Back to sign in",
  Submit: "Submit",
  "Reset your password": "Reset your password",
  "New Password": "New Password",
  "Confirm Password": "Confirm Password",
  "Forgot your password?": "Forgot your password?",
  "Search...": "Search...",
  "Please type a keyword": "Please type a keyword",
  "Open:": "Open:",
  "Open in New Tab:": "Open in New Tab:",
  "Select:": "Select:",
  "Shortcut:": "Shortcut:",
  "Your file(s) is being downloaded. Please check the status here.":
    "Your file(s) is being downloaded. Please check the status here.",
  "Process Initiated": "Process Initiated",
  "Preparing File": "Preparing File",
  "Ready To Download": "Ready To Download",
  Download: "Download",
  Confirmation: "Confirmation",
  "Add Comments (optional)": "Add Comments (optional)",
  Confirm: "Confirm",
  Back: "Back",
  "Are you sure you want to upload file <b>__FILE_NAME__</b> ?":
    "Are you sure you want to upload file <b>__FILE_NAME__</b> ?",
  "File uploading": "File uploading",
  "File upload completed": "File upload completed",
  "Not Started": "Not Started",
  "File upload completed with errors": "File upload completed with errors",
  "File upload failed": "File upload failed",
  "Upload new file": "Upload new file",
  "Sorry, we couldn't find any user matching your search term. Please try a different search term.":
    "Sorry, we couldn't find any user matching your search term. Please try a different search term.",
  "Uploaded Successfully": "Uploaded Successfully",
  "File upload is successful. File processing will begin shortly. Check the activity log to know about the file processing status.":
    "File upload is successful. File processing will begin shortly. Check the activity log to know about the file processing status.",
  "View Status": "View Status",
  "No options": "No options",
  Editor: "Editor",
  Viewer: "Viewer",
  Administrator: "Administrator",
  Add: "Add",
  "User already exist, access granted.": "User already exist, access granted.",
  No: "No",
  Yes: "Yes",
  "{{accessLabel}} role {{action}}": "{{accessLabel}} role {{action}}",
  "Do you really want to Grant **{{action}}** role to **{{userName}}**?":
    "Do you really want to Grant **{{action}}** role to **{{userName}}**?",
  "Activity Log": "Activity Log",
  "Control Center > User Access Management": "Control Center > User Access Management",
  "Select Ad account": "Select Ad account",
  "Enter the name": "Enter the name",
  "Please enter name": "Please enter name",
  "Select access role": "Select access role",
  "Please select a access role": "Please select a access role",
  "Enter the email": "Enter the email",
  "Please enter email": "Please enter email",
  "Recently Opened": "Recently Opened",
  "Please enter email id": "Please enter email id",
  "Enter Password": "Enter Password",
  "Password length must be greater than or equal to 6":
    "Password length must be greater than or equal to 6",
  "Enter Strong Password": "Enter Strong Password",
  "Please enter a strong password": "Please enter a strong password",
  "Confirm New Password": "Confirm New Password",
  "Please enter confirm password": "Please enter confirm password",
  "Do you really want to delete **{{userLabel}}**?":
    "Do you really want to delete **{{userLabel}}**?",
  "{{name}} successfully deleted.": "{{name}} successfully deleted.",
  "{{userLabel}} cannot be deleted because their role(s) are applied on parent business.":
    "{{userLabel}} cannot be deleted because their role(s) are applied on parent business.",
  "Change Log": "Change Log",
  Date: "Date",
  "Changed By": "Changed By",
  Action: "Action",
  "New user created, Access granted.": "New user created, Access granted.",
  "You can not modify this role since it is applied on parent business.":
    "You can not modify this role since it is applied on parent business.",
  "Enter Name": "Enter Name",
  "Basic Info": "Basic Info",
  "Campaign Type": "Campaign Type",
  Guaranteed: "Guaranteed",
  "Non-Guaranteed": "Non-Guaranteed",
  "Please select campaign type": "Please select campaign type",
  Video: "Video",
  "Frequency Capping": "Frequency Capping",
  "Lifetime Frequency": "Lifetime Frequency",
  "Enter Lifetime Frequency": "Enter Lifetime Frequency",
  "Please enter life time frequency": "Please enter life time frequency",
  "Lifetime frequency should be minimum 5000": "Lifetime frequency should be minimum 5000",
  "Lifetime frequency should be maximum 10000": "Lifetime frequency should be maximum 10000",
  "Daily Frequency": "Daily Frequency",
  "Enter Daily Frequency": "Enter Daily Frequency",
  "Please enter daily frequency": "Please enter daily frequency",
  "Daily frequency should be minimum 10": "Daily frequency should be minimum 10",
  "Daily frequency should be maximum 20": "Daily frequency should be maximum 20",
  "Targeting and Cost Metric": "Targeting and Cost Metric",
  "Cost Metric": "Cost Metric",
  CPM: "CPM",
  CPV: "CPV",
  "Please select cost metric": "Please select cost metric",
  "Base Price (CPM)": "Base Price (CPM)",
  "Base Price (CPV)": "Base Price (CPV)",
  "Enter Base Price (CPM)": "Enter Base Price (CPM)",
  "Enter Base Price (CPV)": "Enter Base Price (CPV)",
  "Please enter basic price (CPM)": "Please enter basic price (CPM)",
  "Please enter basic price (CPV)": "Please enter basic price (CPV)",
  "Basic price (CPM) should be minimum __CURRENCY__{{osPackageConfiguration.validations.campaignTemplate.budget.min}}":
    "Basic price (CPM) should be minimum __CURRENCY__{{osPackageConfiguration.validations.campaignTemplate.budget.min}}",
  "Basic price (CPV) should be minimum __CURRENCY__{{osPackageConfiguration.validations.campaignTemplate.budget.min}}":
    "Basic price (CPV) should be minimum __CURRENCY__{{osPackageConfiguration.validations.campaignTemplate.budget.min}}",
  "Basic price (CPM) should be maximum __CURRENCY__{{osPackageConfiguration.validations.campaignTemplate.budget.max}}":
    "Basic price (CPM) should be maximum __CURRENCY__{{osPackageConfiguration.validations.campaignTemplate.budget.max}}",
  "Basic price (CPV) should be maximum __CURRENCY__{{osPackageConfiguration.validations.campaignTemplate.budget.max}}":
    "Basic price (CPV) should be maximum __CURRENCY__{{osPackageConfiguration.validations.campaignTemplate.budget.max}}",
  "Please enter basic price": "Please enter basic price",
  "Targets Surcharge": "Targets Surcharge",
  "Target Name": "Target Name",
  "Select Targets": "Select Targets",
  "Please select targets": "Please select targets",
  "Targets should be minimum 1": "Targets should be minimum 1",
  "Targets should be maximum 20": "Targets should be maximum 20",
  Surcharge: "Surcharge",
  "Surcharge Price": "Surcharge Price",
  "No Targets selected": "No Targets selected",
  Save: "Save",
  "Creation Time": "Creation Time",
  User: "User",
  Description: "Description",
  "Merchants Affected": "Merchants Affected",
  "File Processing Status": "File Processing Status",
  File: "File",
  "No Data Available": "No Data Available",
  Device: "Device",
  Genre: "Genre",
  Channel: "Channel",
  Program: "Program",
  Hour: "Hour",
  "Day of week": "Day of week",
  Gender: "Gender",
  Age: "Age",
  "Ad unit": "Ad unit",
  "Ad unit type": "Ad unit type",
  Segment: "Segment",
  Pricing: "Pricing",
  "Editing point": "Editing point",
  AND: "AND",
  OR: "OR",
  Includes: "Includes",
  Excludes: "Excludes",
  "Save As Draft": "Save As Draft",
  "Step 1: Product Template Details": "Step 1: Product Template Details",
  "Enter the details about your Product Template": "Enter the details about your Product Template",
  "Start Date": "Start Date",
  "Please select start date": "Please select start date",
  "End Date": "End Date",
  "(Optional)": "(Optional)",
  "Please select end date": "Please select end date",
  "Select a date": "Select a date",
  Visibility: "Visibility",
  "Please select visibility": "Please select visibility",
  All: "All",
  "By Ad Account": "By Ad Account",
  "Ad Account": "Ad Account",
  "Add Ad Account": "Add Ad Account",
  Id: "Id",
  "Client id": "Client id",
  "{{selected}} Accounts Selected": "{{selected}} Accounts Selected",
  "Please select Ad account": "Please select Ad account",
  "maximum 100 ad account can be selected": "maximum 100 ad account can be selected",
  Type: "Type",
  "Please select type": "Please select type",
  Packaged: "Packaged",
  "Non-Packaged": "Non-Packaged",
  "Price Per Package": "Price Per Package",
  "Enter Price Per Package": "Enter Price Per Package",
  "Please enter price per package": "Please enter price per package",
  "Total Number of Packages": "Total Number of Packages",
  "Enter Total Number of Packages": "Enter Total Number of Packages",
  "Please enter total number of packages": "Please enter total number of packages",
  "Max Number of Purchasable Packages Per Brand": "Max Number of Purchasable Packages Per Brand",
  "Enter Max Number": "Enter Max Number",
  "Please enter max number of purchasable packages per brand":
    "Please enter max number of purchasable packages per brand",
  "Ad Period Window": "Ad Period Window",
  "Min Ads Period (Days)": "Min Ads Period (Days)",
  "Enter Min Ads Period": "Enter Min Ads Period",
  "Please enter min ads period (days)": "Please enter min ads period (days)",
  "Min ads periods value should be less than date range of ad period window":
    "Min ads periods value should be less than date range of ad period window",
  "Inventory Type": "Inventory Type",
  "Pricing Type": "Pricing Type",
  "Unit Price": "Unit Price",
  Targets: "Targets",
  Edit: "Edit",
  Duplicate: "Duplicate",
  Status: "Status",
  Active: "Active",
  Pause: "Pause",
  Paused: "Paused",
  Activate: "Activate",
  DRAFT: "DRAFT",
  Cancel: "Cancel",
  "has any of": "has any of",
  "has none of": "has none of",
  "equals (=)": "equals (=)",
  "not equals (≠)": "not equals (≠)",
  "Used In Product Templates": "Used In Product Templates",
  "Campaign Template": "Campaign Template",
  "Create New Template": "Create New Template",
  "Search Name": "Search Name",
  "Add Filter": "Add Filter",
  Package: "Package",
  Disable: "Disable",
  is: "is",
  "is not": "is not",
  contains: "contains",
  "does not contains": "does not contains",
  "starts with": "starts with",
  "ends with": "ends with",
  "less than (<)": "less than (<)",
  "less than or equals (<=)": "less than or equals (<=)",
  "greater than (>)": "greater than (>)",
  "greater than or equals (>=)": "greater than or equals (>=)",
  "Campaign Template Disable, Click to Change": "Campaign Template Disable, Click to Change",
  "Campaign Template Enable, Click to Change": "Campaign Template Enable, Click to Change",
  "Campaign Template Not Enabled": "Campaign Template Not Enabled",
  "Cost Per Package": "Cost Per Package",
  "Package Type": "Package Type",
  "Product Type": "Product Type",
  "Non-Package": "Non-Package",
  "Ads Period Window": "Ads Period Window",
  "No.of Media reps": "No.of Media reps",
  "No.of Campaign Templates": "No.of Campaign Templates",
  "Add/Remove Columns": "Add/Remove Columns",
  "Create Product Template": "Create Product Template",
  "No. of Ad Accounts": "No. of Ad Accounts",
  "No. of Campaign Templates": "No. of Campaign Templates",
  "Step 2: Campaign Template Details": "Step 2: Campaign Template Details",
  "Add More Campaign Templates": "Add More Campaign Templates",
  "Campaign Template Name": "Campaign Template Name",
  "Budget Allocation": "Budget Allocation",
  Priority: "Priority",
  "*Sum of allocation should be 100%": "*Sum of allocation should be 100%",
  "Sum of allocation should be 100%": "Sum of allocation should be 100%",
  "Total Budget Allocation": "Total Budget Allocation",
  "Add Campaign Template": "Add Campaign Template",
  "(Sum of allocation should be 100%)": "(Sum of allocation should be 100%)",
  "Select the campaign type suitable for your product template":
    "Select the campaign type suitable for your product template",
  "Product Template Disable, Click to Change": "Product Template Disable, Click to Change",
  "Product Template Enable, Click to Change": "Product Template Enable, Click to Change",
  "Product Template Not Enabled": "Product Template Not Enabled",
  View: "View",
  "1. Product Template Details": "1. Product Template Details",
  "2. Campaign Template Details": "2. Campaign Template Details",
  "Step 1/2": "Step 1/2",
  "See Order Details": "See Order Details",
  "Visibility Duration": "Visibility Duration",
  "Max: 10,000": "Max: 10,000",
  "Max: 1,000": "Max: 1,000",
  "Please select atleast one row.": "Please select atleast one row.",
  "Account Manager": "Account Manager",
  "OMS Package Viewer": "OMS Package Viewer",
  "OMS Package Editor": "OMS Package Editor",
  "Select Campaign Template for your Product Template":
    "Select Campaign Template for your Product Template",
  "See Details": "See Details",
  "Data will reset. Are you sure you want to change this?":
    "Data will reset. Are you sure you want to change this?",
  "Do you really want to remove campaign template?":
    "Do you really want to remove campaign template?",
  "You can not delete your own user.": "You can not delete your own user.",
  "Select an option": "Select an option",
  "Select an options": "Select an options",
  "Enter a value": "Enter a value",
  Apply: "Apply",
  Refresh: "Refresh",
  Advertisers: "Advertisers",
  "Copy Of {{name}}": "Copy Of {{name}}",
  "Internal Error": "Internal Error",
  "Please complete all required fields.": "Please complete all required fields.",
  "Target Conditions": "Target Conditions",
  "Target Preview": "Target Preview",
  "No Preview Data": "No Preview Data",
  "Coming Soon": "Coming Soon",
  Done: "Done",
  "Add Campaign Templates": "Add Campaign Templates",
  "Add Product Templates": "Add Product Templates",
  "Value should be greater then 0": "Value should be greater then 0",
  "Campaign Templates used in {{name}}": "Campaign Templates used in {{name}}",
  "{{name}} used in Product Template": "{{name}} used in Product Template",
  "Targets for {{name}}": "Targets for {{name}}",
  "Your changes will be lost. Are you sure want to close this?":
    "Your changes will be lost. Are you sure want to close this?",
  Close: "Close",
  "Save & Activate": "Save & Activate",
  "Select Advertisers": "Select Advertisers",
  "{{count}} Advertiser selected": "{{count}} Advertiser selected",
  "Select Campaign Templates": "Select Campaign Templates",
  "{{count}} Campaign Template Selected": "{{count}} Campaign Template Selected",
  "Select Product Templates": "Select Product Templates",
  "{{count}} Product Template Selected": "{{count}} Product Template Selected",
  "Next Page": "Next Page",
  "Previous Page": "Previous Page",
  "Please enter valid email": "Please enter valid email",
  "Explore Features": "Explore Features",
  "The leaf level smallest unit that can be sold by the sales team. This directly corresponds to campaigns that will be executed.":
    "The leaf level smallest unit that can be sold by the sales team. This directly corresponds to campaigns that will be executed.",
  "A group of campaign templates sold by the sales team or directly purchased by advertisers.":
    "A group of campaign templates sold by the sales team or directly purchased by advertisers.",
  "Please select a access value": "Please select a access value",
  "User Failed": "User Failed",
  Ready: "Ready",
  Completed: "Completed",
  "System Failed": "System Failed",
  "({{count}} errors)": "({{count}} errors)",
  "Bulk Ad Account Onboarding": "Bulk Ad Account Onboarding",
  "Basic price (CPM) should be minimum 1,000": "Basic price (CPM) should be minimum 1,000",
  "Basic price (CPM) should be maximum 10,000": "Basic price (CPM) should be maximum 10,000",
  "Daily frequency should be maximum 1,000": "Daily frequency should be maximum 1,000",
  "Lifetime frequency should be maximum 10,000": "Lifetime frequency should be maximum 10,000",
  "Campaign Template activated succesfully": "Campaign Template activated succesfully",
  "Product Template activated successfully": "Product Template activated successfully",
  "Creatives not yet added. Add Your first creative.":
    "Creatives not yet added. Add Your first creative.",
  Creative: "Creative",
  "Add Creative": "Add Creative",
  "Preview Order": "Preview Order",
  "Creative Details": "Creative Details",
  "Universal URL": "Universal URL",
  "Destination URL": "Destination URL",
  "Specific URL'S": "Specific URL'S",
  "Describe your creative": "Describe your creative",
  "Web URL": "Web URL",
  "iOS URL": "iOS URL",
  "Android URL": "Android URL",
  "Enter URL": "Enter URL",
  "Add Companion Images": "Add Companion Images",
  "Event Tracking": "Event Tracking",
  "Add tracker": "Add tracker",
  "Impression Tracker": "Impression Tracker",
  "Click Tracker": "Click Tracker",
  "Image 1": "Image 1",
  "Image 2": "Image 2",
  "Choose from library": "Choose from library",
  "Upload from system": "Upload from system",
  "Creative Library": "Creative Library",
  "Choose Creative": "Choose Creative",
  "Clear All": "Clear All",
  "Please upload video": "Please upload video",
  "Please upload image": "Please upload image",
  "Please enter url": "Please enter url",
  "Please enter valid url": "Please enter valid url",
  Upload: "Upload",
  "Media Size": "Media Size",
  Duration: "Duration",
  Dimensions: "Dimensions",
  "Are you sure you want to clear all the selected filters?":
    "Are you sure you want to clear all the selected filters?",
  "Add Tags": "Add Tags",
  "Edit Tags": "Edit Tags",
  "Search Tags": "Search Tags",
  "Type here and enter": "Type here and enter",
  Choose: "Choose",
  "Video Preview": "Video Preview",
  "Incorrect file format chosen. please upload mp4, mov, mpg, wmv, avi files only.":
    "Incorrect file format chosen. please upload mp4, mov, mpg, wmv, avi files only.",
  "Please add at least one creative": "Please add at least one creative",
  Preview: "Preview",
  "1. Order Details": "1. Order Details",
  "Enter the details about your Order": "Enter the details about your Order",
  "Name should be maximum 200 characters": "Name should be maximum 200 characters",
  "Order Description": "Order Description",
  "Enter Order Description": "Enter Order Description",
  "Please enter order description": "Please enter order description",
  "Order decription should be maximum 1000 characters":
    "Order decription should be maximum 1000 characters",
  "Brand Info": "Brand Info",
  Brand: "Brand",
  "Enter Brand": "Enter Brand",
  "Please select brand": "Please select brand",
  "IAB Category": "IAB Category",
  "Enter IAB Category": "Enter IAB Category",
  "Please select iab category": "Please select iab category",
  "Billing Month": "Billing Month",
  "Custom Category": "Custom Category",
  "Enter Custom Category": "Enter Custom Category",
  "Please select custom category": "Please select custom category",
  "2. Product Templates": "2. Product Templates",
  "Select Product Templates for your Order": "Select Product Templates for your Order",
  "Order cost": "Order cost",
  "Enter Cost": "Enter Cost",
  "Note: Budget is allocated to product templates in the next page.":
    "Note: Budget is allocated to product templates in the next page.",
  "Please enter order cost": "Please enter order cost",
  "Add Product Template": "Add Product Template",
  "Add More Product Templates": "Add More Product Templates",
  "Please match the total cost and order cost": "Please match the total cost and order cost",
  "Please select package templates": "Please select package templates",
  "Minimum {{minAdDurationDays}} days need to be selected":
    "Minimum {{minAdDurationDays}} days need to be selected",
  "Minimum {{minAdDurationDays}} days to be selected":
    "Minimum {{minAdDurationDays}} days to be selected",
  "Dates shoud be in range of Ads period window": "Dates shoud be in range of Ads period window",
  "Package Cost:": "Package Cost:",
  Quantity: "Quantity",
  "Please select quantity": "Please select quantity",
  "Package Total Cost": "Package Total Cost",
  "Please enter package total cost": "Please enter package total cost",
  "Enter value": "Enter value",
  "Bonus Rate": "Bonus Rate",
  "Total Package Budget": "Total Package Budget",
  "Price Breakdown": "Price Breakdown",
  "Total Cost": "Total Cost",
  "Total Budget": "Total Budget",
  "Incorrect file format chosen": "Incorrect file format chosen",
  "Incorrect file format chosen. please upload {{formatList}} files only.":
    "Incorrect file format chosen. please upload {{formatList}} files only.",
  "You can only upload file with max size upto {{size}}":
    "You can only upload file with max size upto {{size}}",
  "You can only upload file with min size {{size}}":
    "You can only upload file with min size {{size}}",
  "Please choose correct media type to proceed further":
    "Please choose correct media type to proceed further",
  "You can only choose file with dimension {{width}} x {{height}}":
    "You can only choose file with dimension {{width}} x {{height}}",
  "You can only choose file with max size upto {{maxFileSizeInMB}}MB":
    "You can only choose file with max size upto {{maxFileSizeInMB}}MB",
  "You can only choose file with min size {{minFileSizeInMB}}MB":
    "You can only choose file with min size {{minFileSizeInMB}}MB",
  "You can only choose video with a duration between {{min}} and {{max}} seconds":
    "You can only choose video with a duration between {{min}} and {{max}} seconds",
  "You can only choose creative with {{defaultCreativeLanguage}} language":
    "You can only choose creative with {{defaultCreativeLanguage}} language",
  "Dimension of the {{displayName}} should be {{widthValidation}}x{{heightValidation}}.":
    "Dimension of the {{displayName}} should be {{widthValidation}}x{{heightValidation}}.",
  "Dimension of the {{displayName}} should be minimum {{widthValidation}}x{{heightValidation}}.":
    "Dimension of the {{displayName}} should be minimum {{widthValidation}}x{{heightValidation}}.",
  "Dimension of the {{displayName}} should be maximum {{widthValidation}}x{{heightValidation}}.":
    "Dimension of the {{displayName}} should be maximum {{widthValidation}}x{{heightValidation}}.",
  "{{mediaDisplayName}} aspect ratio should be {{width}}:{{height}}":
    "{{mediaDisplayName}} aspect ratio should be {{width}}:{{height}}",
  "{{mediaDisplayName}} aspect ratio should be between {{minWidth}}:{{minHeight}} to {{maxWidth}}:{{maxHeight}}":
    "{{mediaDisplayName}} aspect ratio should be between {{minWidth}}:{{minHeight}} to {{maxWidth}}:{{maxHeight}}",
  "{{mediaDisplayName}} aspect ratio should not be more than {{maxWidth}}:{{maxHeight}}":
    "{{mediaDisplayName}} aspect ratio should not be more than {{maxWidth}}:{{maxHeight}}",
  "{{mediaDisplayName}} aspect ratio should not be less than {{minWidth}}:{{minHeight}}":
    "{{mediaDisplayName}} aspect ratio should not be less than {{minWidth}}:{{minHeight}}",
  "Duration of the {{mediaDisplayName}} should be between {{min}} to {{max}} seconds.":
    "Duration of the {{mediaDisplayName}} should be between {{min}} to {{max}} seconds.",
  "Duration of the {{mediaDisplayName} should be minimum {{min}} seconds.":
    "Duration of the {{mediaDisplayName} should be minimum {{min}} seconds.",
  "Duration of the {{mediaDisplayName}} should be maximum {{max}} seconds.":
    "Duration of the {{mediaDisplayName}} should be maximum {{max}} seconds.",
  "Error on load media": "Error on load media",
  "Are you sure want to remove this creative?": "Are you sure want to remove this creative?",
  "File Type": "File Type",
  "Max File Size": "Max File Size",
  "Duration: {{min}} seconds to {{max}} seconds": "Duration: {{min}} seconds to {{max}} seconds",
  "Duration: upto {{max}} seconds": "Duration: upto {{max}} seconds",
  "No dimensions found": "No dimensions found",
  "No Creatives Found": "No Creatives Found",
  "Please enter tags of length less than 50": "Please enter tags of length less than 50",
  "You can upload creative of {{validationMessage}}":
    "You can upload creative of {{validationMessage}}",
  "dimension {{pWidth}} x {{pHeight}} and size upto {{maxFileSizeInMB}}MB":
    "dimension {{pWidth}} x {{pHeight}} and size upto {{maxFileSizeInMB}}MB",
  "dimension {{pWidth}} x {{pHeight}}": "dimension {{pWidth}} x {{pHeight}}",
  "size upto {{maxFileSizeInMB}}MB": "size upto {{maxFileSizeInMB}}MB",
  "3. Creative": "3. Creative",
  "Update the creative details, These are the Ads that customer’s will interact with.":
    "Update the creative details, These are the Ads that customer’s will interact with.",
  "Total Creatives": "Total Creatives",
  "Add New": "Add New",
  "Active, Click to Pause": "Active, Click to Pause",
  "Paused, Click to Activate": "Paused, Click to Activate",
  "Click to copy": "Click to copy",
  "Ad Scheduling": "Ad Scheduling",
  "Select Day": "Select Day",
  "Select Time Slot": "Select Time Slot",
  "Clear Settings": "Clear Settings",
  "This will reset your Time of Day & Day of Week settings. Are you sure you want to reset?":
    "This will reset your Time of Day & Day of Week settings. Are you sure you want to reset?",
  Reset: "Reset",
  "Add more time slots": "Add more time slots",
  "Based on account time zone: {{timezone}}": "Based on account time zone: {{timezone}}",
  "Schedule ad for specific date": "Schedule ad for specific date",
  "No Start DAte": "No Start DAte",
  "No End Date": "No End Date",
  "Select All": "Select All",
  "All Days": "All Days",
  "(Monday to Friday)": "(Monday to Friday)",
  "(Saturday to Sunday)": "(Saturday to Sunday)",
  Weekend: "Weekend",
  Weekdays: "Weekdays",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  "Delete this row": "Delete this row",
  "Duplicate selected time slots": "Duplicate selected time slots",
  "Add time slots": "Add time slots",
  "Order List": "Order List",
  "Under Review": "Under Review",
  Archived: "Archived",
  Rejected: "Rejected",
  "Create New Order": "Create New Order",
  "Ads Duration": "Ads Duration",
  "No. of Product Templates": "No. of Product Templates",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  Approved: "Approved",
  Comments: "Comments",
  "Comment regarding {{entityTitle}}": "Comment regarding {{entityTitle}}",
  "Comment to reject {{entityTitle}}": "Comment to reject {{entityTitle}}",
  "Are you sure you want to comment & reject this {{ entityTitle }}?":
    "Are you sure you want to comment & reject this {{ entityTitle }}?",
  "Are you sure you want to do the undo the status?":
    "Are you sure you want to do the undo the status?",
  "Are you sure you want to approve this {{entityTitle}}?":
    "Are you sure you want to approve this {{entityTitle}}?",
  Reject: "Reject",
  "Approve & Next": "Approve & Next",
  "Comment & Reject": "Comment & Reject",
  "No Comments yet": "No Comments yet",
  "Enter comment here": "Enter comment here",
  "Max number of purchasable packages per brand should be less than total number of packages":
    "Max number of purchasable packages per brand should be less than total number of packages",
  "Rejected successfully!": "Rejected successfully!",
  "Reject undone successfully!": "Reject undone successfully!",
  "Approved successfully!": "Approved successfully!",
  "Approve undone successfully!": "Approve undone successfully!",
  "Approval Pending": "Approval Pending",
  "Used in Orders": "Used in Orders",
  "No result found for given search term": "No result found for given search term",
  "No data found": "No data found",
  "Special Characters ({{chars}}) are not allowed":
    "Special Characters ({{chars}}) are not allowed",
  "Dates shoud be in range of Ads period window ({{startDate}} - {{endDate}})":
    "Dates shoud be in range of Ads period window ({{startDate}} - {{endDate}})",
  Setting: "Setting",
  "Select Brand": "Select Brand",
  "Select IAB Category": "Select IAB Category",
  "Select Custom Category": "Select Custom Category",
  "Please enter end date": "Please enter end date",
  "Please enter start date": "Please enter start date",
  "Are you sure you want delete, your data will be lost.":
    "Are you sure you want delete, your data will be lost.",
  MB: "MB",
  Seconds: "Seconds",
  image: "image",
  "Please set start date or end date": "Please set start date or end date",
  "Please configure ads scheduling": "Please configure ads scheduling",
  "Please select select day": "Please select select day",
  "Please select time slot": "Please select time slot",
  "Your companion image will appear here": "Your companion image will appear here",
  "Order Preview": "Order Preview",
  "Submit for review": "Submit for review",
  "Campaign template not enabled.": "Campaign template not enabled.",
  "Campaign template enabled. Click to change.": "Campaign template enabled. Click to change.",
  "Campaign template not enabled. Activate now.": "Campaign template not enabled. Activate now.",
  "Campaign template enabled.": "Campaign template enabled.",
  "Creative deleted successfully": "Creative deleted successfully",
  "Creative status updated successfully": "Creative status updated successfully",
  "The total amount payable for your order, including the cost of all packages.":
    "The total amount payable for your order, including the cost of all packages.",
  "Assign a category specific to your needs for this order.":
    "Assign a category specific to your needs for this order.",
  "Choose the category that best describes the industry or vertical this order falls under.":
    "Choose the category that best describes the industry or vertical this order falls under.",
  "The month your payment will be processed for this order.":
    "The month your payment will be processed for this order.",
  "Specify the brand for which this order is being placed. If the brand you need is not listed, contact customer support.":
    "Specify the brand for which this order is being placed. If the brand you need is not listed, contact customer support.",
  "Order active, click to change": "Order active, click to change",
  "Order pause, click to change": "Order pause, click to change",
  "Order delivered": "Order delivered",
  "Order rejected": "Order rejected",
  "Order scheduled": "Order scheduled",
  "Order under review": "Order under review",
  Delivered: "Delivered",
  "Order not active": "Order not active",
  "Your video file will appear here": "Your video file will appear here",
  "Name of product template": "Name of product template",
  "Name of campaign template": "Name of campaign template",
  "Name of order": "Name of order",
  "Description of order": "Description of order",
  "Name of creative": "Name of creative",
  "Description of creative": "Description of creative",
  "URL of the landing page where the viewer will land after clicking the ad.":
    "URL of the landing page where the viewer will land after clicking the ad.",
  "Video ad to be played during content streaming.":
    "Video ad to be played during content streaming.",
  "Companion image to be displayed alongside the video ad during its play. View the preview for visual reference.":
    "Companion image to be displayed alongside the video ad during its play. View the preview for visual reference.",
  "Third-party tracker configuration for verifying ad delivery.":
    "Third-party tracker configuration for verifying ad delivery.",
  "Status of this {{label}} has been changed from **{{previousStatus}}** to **{{status}}** by {{userName}} on {{timestamp}}":
    "Status of this {{label}} has been changed from **{{previousStatus}}** to **{{status}}** by {{userName}} on {{timestamp}}",
  "Status of this {{label}} has been changed to **{{status}}** by {{userName}} on {{timestamp}}":
    "Status of this {{label}} has been changed to **{{status}}** by {{userName}} on {{timestamp}}",
  "Date and time slots during which the ad is scheduled to play.":
    "Date and time slots during which the ad is scheduled to play.",
  "The maximum package quantity usage for {{brandName}} has been reached.":
    "The maximum package quantity usage for {{brandName}} has been reached.",
  "The maximum package usage has been exhausted.": "The maximum package usage has been exhausted.",
  "Only {{availableBookingPerBrand}} of {{packageName}} left for {{brandName}}.":
    "Only {{availableBookingPerBrand}} of {{packageName}} left for {{brandName}}.",
  "Only {{availableBookingOverall}} remaining for {{packageName}}.":
    "Only {{availableBookingOverall}} remaining for {{packageName}}.",
  "The video size is invalid. Please check the dimensions and try again.":
    "The video size is invalid. Please check the dimensions and try again.",
  "The video resolution is not supported. Please use a compatible resolution.":
    "The video resolution is not supported. Please use a compatible resolution.",
  "The file type is not supported. Please use a supported file format.":
    "The file type is not supported. Please use a supported file format.",
  "No audio detected. Please ensure the file contains audio and try again.":
    "No audio detected. Please ensure the file contains audio and try again.",
  "The image size is invalid. Please check the dimensions and try again.":
    "The image size is invalid. Please check the dimensions and try again.",
  "The image resolution is not supported. Please use a compatible resolution.":
    "The image resolution is not supported. Please use a compatible resolution.",
  Scheduled: "Scheduled",
  "Companion image for mobile & tablet": "Companion image for mobile & tablet",
  "Companion image for desktop": "Companion image for desktop",
  "Web URL of the landing page where the viewer will land after clicking the ad.":
    "Web URL of the landing page where the viewer will land after clicking the ad.",
  "iOS app deep link URL where the viewer will land after clicking the ad.":
    "iOS app deep link URL where the viewer will land after clicking the ad.",
  "Android app deep link URL where the viewer will land after clicking the ad.":
    "Android app deep link URL where the viewer will land after clicking the ad.",
  "Invalid credentials, Please log in with appropriate credentials.":
    "Invalid credentials, Please log in with appropriate credentials.",
  "Your verification link has been expired, Please try again.":
    "Your verification link has been expired, Please try again.",
  "Invalid verification link, Please use correct link to verify user.":
    "Invalid verification link, Please use correct link to verify user.",
  "Something went wrong. we are not able to validate your request. please try again.":
    "Something went wrong. we are not able to validate your request. please try again.",
  "Oops! The password reset link you used has either expired or is invalid. Password reset links are time-sensitive and can only be used once. Please click 'Forgot Password' again to receive a fresh link and reset your password":
    "Oops! The password reset link you used has either expired or is invalid. Password reset links are time-sensitive and can only be used once. Please click 'Forgot Password' again to receive a fresh link and reset your password",
  "It seems some input parameters are not valid. Please update your inputs and try again.":
    "It seems some input parameters are not valid. Please update your inputs and try again.",
  "Unable to load captcha": "Unable to load captcha",
  "You will recieve steps to reset your password if the email address exists in our system":
    "You will recieve steps to reset your password if the email address exists in our system",
  "List of all orders created for your account": "List of all orders created for your account",
  "All Accounts": "All Accounts",
  "No account found": "No account found",
  "No Search Result Found": "No Search Result Found",
  "No result found": "No result found",
  "Try different keyword": "Try different keyword",
  "Loading...": "Loading...",
  You: "You",
  "Are you sure you want to {{passiveName}} {{name}} {{entity}}?":
    "Are you sure you want to {{passiveName}} {{name}} {{entity}}?",
  "Please enter value": "Please enter value",
  "please enter something": "please enter something",
  "Please enter valid value": "Please enter valid value",
  "Are you sure want to delete, you will lost your selected value ?":
    "Are you sure want to delete, you will lost your selected value ?",
  "No preview available": "No preview available",
  "{{failedCount}} errors": "{{failedCount}} errors",
  "In Progress": "In Progress",
  "Partially Completed": "Partially Completed",
  Failed: "Failed",
  Discarded: "Discarded",
  Resolved: "Resolved",
  "The leaf level smallest unit that can be sold by the sales team. This directly corresponds to campaigns that will be executed":
    "The leaf level smallest unit that can be sold by the sales team. This directly corresponds to campaigns that will be executed",
  "Please select value": "Please select value",
  "Undo Reject": "Undo Reject",
  "Undo Approve": "Undo Approve",
  "Order active": "Order active",
  "Final Price (Base Price + Surcharge):": "Final Price (Base Price + Surcharge):",
  "The unit price (CPM/CPV) of the campaign template.":
    "The unit price (CPM/CPV) of the campaign template.",
  "The unit price (CPM) of the campaign template.":
    "The unit price (CPM) of the campaign template.",
  "The unit price (CPV) of the campaign template.":
    "The unit price (CPV) of the campaign template.",
  "The cost of a single unit of the product template.":
    "The cost of a single unit of the product template.",
  "Base Price": "Base Price",
  "Total Budget Allocation:": "Total Budget Allocation:",
  "Comprehensive list of all orders across all ad accounts.":
    "Comprehensive list of all orders across all ad accounts.",
  "List of all orders pending review across all ad accounts.":
    "List of all orders pending review across all ad accounts.",
  "Error while fetching booking information": "Error while fetching booking information",
  "2. Product Templates And Pricing": "2. Product Templates And Pricing",
  "Order details rejected": "Order details rejected",
  "Error while fetching campaign template information":
    "Error while fetching campaign template information",
  "Error while fetching package information": "Error while fetching package information",
  "Editing the Campaign Template is not allowed as it has already been utilized in one or more Product Templates.":
    "Editing the Campaign Template is not allowed as it has already been utilized in one or more Product Templates.",
  "Successfully updated the creative": "Successfully updated the creative",
  "Editing the Product Template is not allowed as it has already been booked in one or more Orders.":
    "Editing the Product Template is not allowed as it has already been booked in one or more Orders.",
  "Comment Only": "Comment Only",
  "Error while fetching creative": "Error while fetching creative",
  "Failed to transcode the video. Please check video and contact us if the problem persists.":
    "Failed to transcode the video. Please check video and contact us if the problem persists.",
  "Failed to transcode the video due to a timeout. Please try again later, and contact us if the problem persists.":
    "Failed to transcode the video due to a timeout. Please try again later, and contact us if the problem persists.",
  "Creative processing is in progress, Please wait a while and comeback again for review.":
    "Creative processing is in progress, Please wait a while and comeback again for review.",
  "All creatives failed to process. Upload at least one correct creative to approve the order.":
    "All creatives failed to process. Upload at least one correct creative to approve the order.",
  Cost: "Cost",
  "Please choose product templates that suit your needs. These templates can contain multiple campaign templates and will be included in your order. Ensure they meet your requirements before submitting the order.":
    "Please choose product templates that suit your needs. These templates can contain multiple campaign templates and will be included in your order. Ensure they meet your requirements before submitting the order.",
  "Please select campaign template that align with your product objective. These templates represent executable inventory setups and will be included in your product template to create a package":
    "Please select campaign template that align with your product objective. These templates represent executable inventory setups and will be included in your product template to create a package",
  "Please select campaign template": "Please select campaign template",
  "You can choose creative of {{validationMessage}}":
    "You can choose creative of {{validationMessage}}",
  "dimension {{pWidth}} x {{pHeight}} px and size upto {{maxFileSizeInMB}}MB":
    "dimension {{pWidth}} x {{pHeight}} px and size upto {{maxFileSizeInMB}}MB",
  "dimension {{pWidth}} x {{pHeight}} px": "dimension {{pWidth}} x {{pHeight}} px",
  "The duration for which this product template will be available for booking":
    "The duration for which this product template will be available for booking",
  "A packaged product template refers to an item that is bundled as a package and can be purchased as a single unit of that package.":
    "A packaged product template refers to an item that is bundled as a package and can be purchased as a single unit of that package.",
  "A non-packaged product is an item that can be booked based on an allocated budget":
    "A non-packaged product is an item that can be booked based on an allocated budget",
  "The maximum quantity of packages that can be sold for this product template.":
    "The maximum quantity of packages that can be sold for this product template.",
  "The maximum number of product templates that can be sold to a single brand.":
    "The maximum number of product templates that can be sold to a single brand.",
  "This specifies the duration for which bookings can be accepted for this product template. If not provided, by default, bookings will be allowed without any restriction.":
    "This specifies the duration for which bookings can be accepted for this product template. If not provided, by default, bookings will be allowed without any restriction.",
  "This indicates the minimum number of days for which this product template can be booked. If not provided, by default, it will be considered as 1 day.":
    "This indicates the minimum number of days for which this product template can be booked. If not provided, by default, it will be considered as 1 day.",
  "The ad accounts permitted to book this product template, which can include all ad accounts or a specific list of up to 100 ad accounts.":
    "The ad accounts permitted to book this product template, which can include all ad accounts or a specific list of up to 100 ad accounts.",
  "Guaranteed campaign promises the impressions to be delivered. Non-Guaranteed will serve impression as per availability.":
    "Guaranteed campaign promises the impressions to be delivered. Non-Guaranteed will serve impression as per availability.",
  "Lifetime frequency indicates the maximum number of times an ad from the campaign can be shown to an individual viewer over its entire campaign duration.":
    "Lifetime frequency indicates the maximum number of times an ad from the campaign can be shown to an individual viewer over its entire campaign duration.",
  "Daily frequency refers to the maximum number of times an ad from the campaign can be shown to an individual viewer in a single day.":
    "Daily frequency refers to the maximum number of times an ad from the campaign can be shown to an individual viewer in a single day.",
  "Please choose the method by which you prefer to charge the customer.":
    "Please choose the method by which you prefer to charge the customer.",
  Enable: "Enable",
  Next: "Next",
  "Status updated successfully": "Status updated successfully",
  "Not Set": "Not Set",
  "This date reflects the planned start date for ads associated with this product template.":
    "This date reflects the planned start date for ads associated with this product template.",
  "This date reflects the planned end date for ads associated with this product template.":
    "This date reflects the planned end date for ads associated with this product template.",
  "Cost of the product template for this order": "Cost of the product template for this order",
  "Additional budget to be provided for this product template.":
    "Additional budget to be provided for this product template.",
  "Total budget allocated for ads displayed using this product template":
    "Total budget allocated for ads displayed using this product template",
  "Number of units you want to order for this product template.":
    "Number of units you want to order for this product template.",
  "Comment and Submit for review": "Comment and Submit for review",
  "View comments": "View comments",
  Analytics: "Analytics",
  "Ad Spend": "Ad Spend",
  "Ad Impressions": "Ad Impressions",
  "Ad Clicks": "Ad Clicks",
  CTR: "CTR",
  "The number of times an advertisement is displayed to users on your platform. Ad impressions provide valuable insights into the reach and visibility of your advertising campaigns.":
    "The number of times an advertisement is displayed to users on your platform. Ad impressions provide valuable insights into the reach and visibility of your advertising campaigns.",
  "The number of times a user clicks on an advertisement on your platform. Ad clicks provide a measure of user engagement with your advertisements and can indicate the success of your advertising campaigns. Keep track of ad clicks to understand which ads are resonating with your audience.":
    "The number of times a user clicks on an advertisement on your platform. Ad clicks provide a measure of user engagement with your advertisements and can indicate the success of your advertising campaigns. Keep track of ad clicks to understand which ads are resonating with your audience.",
  "The ratio of clicks to impressions for an advertisement on your platform. CTR is calculated by dividing the number of clicks by the number of impressions and expressing the result as a percentage.":
    "The ratio of clicks to impressions for an advertisement on your platform. CTR is calculated by dividing the number of clicks by the number of impressions and expressing the result as a percentage.",
  "Filter Applicable:": "Filter Applicable:",
  "Order Performance": "Order Performance",
  Order: "Order",
  "Product Template": "Product Template",
  Ad: "Ad",
  "Ad Performance": "Ad Performance",
  "Seach Order Name": "Seach Order Name",
  Breakdown: "Breakdown",
  Metrics: "Metrics",
  "Ad Program Insights": "Ad Program Insights",
  "Search Order Name": "Search Order Name",
  "Download Report": "Download Report",
  "{{no}} Filter Applicable:": "{{no}} Filter Applicable:",
  "Both password should match": "Both password should match",
  "Password must contain atleast one uppercase, one lowercase & one digit, length must be 8 to 64.":
    "Password must contain atleast one uppercase, one lowercase & one digit, length must be 8 to 64.",
  "Password must not contain repetative characters":
    "Password must not contain repetative characters",
  "Please login with appropriate credential": "Please login with appropriate credential",
  Okay: "Okay",
  "The name contains certain characters that aren't allowed.":
    "The name contains certain characters that aren't allowed.",
  "Maximum characters should be 50": "Maximum characters should be 50",
  "Password has been successfully changed, please login again.":
    "Password has been successfully changed, please login again.",
  "Something went wrong, Please try again!": "Something went wrong, Please try again!",
  "Please retry again!": "Please retry again!",
  "Max packages allowed per brand": "Max packages allowed per brand",
  "Min. Ads Period (days)": "Min. Ads Period (days)",
  "Turning this off will pause your Ad Scheduling settings. Are you sure you want to proceed?":
    "Turning this off will pause your Ad Scheduling settings. Are you sure you want to proceed?",
  "This will reset your Start Date & End Date configurations. Are you sure you want to reset?":
    "This will reset your Start Date & End Date configurations. Are you sure you want to reset?",
  Creatives: "Creatives",
  "Image Preview": "Image Preview",
  "Please select a creative": "Please select a creative",
  "Video processing is in progress. Please check back for the status in a few minutes.":
    "Video processing is in progress. Please check back for the status in a few minutes.",
  Yesterday: "Yesterday",
  "Last 7 Days": "Last 7 Days",
  "Last 30 Days": "Last 30 Days",
  "This Month": "This Month",
  "Last Month": "Last Month",
  "This Quarter": "This Quarter",
  "Last Quarter": "Last Quarter",
  Custom: "Custom",
  "We've Encountered a Server Error": "We've Encountered a Server Error",
  "Dont Worry! We've dispatched our team of best engineers to fix it right away.":
    "Dont Worry! We've dispatched our team of best engineers to fix it right away.",
  "Account Management": "Account Management",
  granted: "granted",
  revoked: "revoked",
  "All selected targets will be applied together using an AND condition.":
    "All selected targets will be applied together using an AND condition.",
  "No creatives added yet.": "No creatives added yet.",
  "Analyze the performance of all orders created across all ad accounts.":
    "Analyze the performance of all orders created across all ad accounts.",
  "Analyze the performance of all the ads from the orders created across all ad accounts.":
    "Analyze the performance of all the ads from the orders created across all ad accounts.",
  "No product templates added yet.": "No product templates added yet.",
  "Channel Name": "Channel Name",
  "Channel Id": "Channel Id",
  "Program Name": "Program Name",
  "Program Id": "Program Id",
  "Episode Name": "Episode Name",
  "Episode Id": "Episode Id",
  "Schedule Reports": "Schedule Reports",
  Reports: "Reports",
  "The report name serves as an identifier for easy reference. It will be mentioned in the email you receive when scheduling the report.":
    "The report name serves as an identifier for easy reference. It will be mentioned in the email you receive when scheduling the report.",
  "Indicates the current status of your report.": "Indicates the current status of your report.",
  "Report Name": "Report Name",
  "List of reports which are already configured for your account.":
    "List of reports which are already configured for your account.",
  "Clear Multi-Sort": "Clear Multi-Sort",
  "Report Type": "Report Type",
  "Specifies report format which are available for download.":
    "Specifies report format which are available for download.",
  "Time Period": "Time Period",
  "The duration covered in the report, indicating the timeframe for which data is analyzed.":
    "The duration covered in the report, indicating the timeframe for which data is analyzed.",
  "Last Run": "Last Run",
  "Next Run": "Next Run",
  Emails: "Emails",
  Report: "Report",
  "The date and time when the report was last generated.":
    "The date and time when the report was last generated.",
  "The scheduled date and time for the next execution of the report.":
    "The scheduled date and time for the next execution of the report.",
  "Email addresses to which the report is sent upon completion or at scheduled intervals.":
    "Email addresses to which the report is sent upon completion or at scheduled intervals.",
  "A report providing detailed information and analysis based on specified criteria or parameters.":
    "A report providing detailed information and analysis based on specified criteria or parameters.",
  "Enter a unique name for your report that helps in its easy identification. This name will be a part of the Email that you receive":
    "Enter a unique name for your report that helps in its easy identification. This name will be a part of the Email that you receive",
  "Select a delivery cadence for your report.": "Select a delivery cadence for your report.",
  "The chosen date indicates when you'll stop receiving this report.":
    "The chosen date indicates when you'll stop receiving this report.",
  "Types of Report which are available for Download":
    "Types of Report which are available for Download",
  "Time range for the data in the report": "Time range for the data in the report",
  "List of users to whom the report will be delivered":
    "List of users to whom the report will be delivered",
  "Report Time Period": "Report Time Period",
  Now: "Now",
  Daily: "Daily",
  Weekly: "Weekly",
  "Not available for selected report type": "Not available for selected report type",
  "Email IDs": "Email IDs",
  "Select Time Period": "Select Time Period",
  "Enter Report Name": "Enter Report Name",
  "All scheduled reports will be generated and delivered by 11 am in {{zone}} timezone":
    "All scheduled reports will be generated and delivered by 11 am in {{zone}} timezone",
  "Enter Email Addresses": "Enter Email Addresses",
  "Report Delivery": "Report Delivery",
  "Select Date": "Select Date",
  "Please enter Report Name": "Please enter Report Name",
  "Please select Time Period for your reports": "Please select Time Period for your reports",
  "Please select day of week for your report delivery":
    "Please select day of week for your report delivery",
  "Report has been scheduled successfully.": "Report has been scheduled successfully.",
  "Report details has been updated successfully!": "Report details has been updated successfully!",
  "Error occured while saving details.": "Error occured while saving details.",
  "Something went wrong while fetching information.":
    "Something went wrong while fetching information.",
  "Data entered for report creation has not been saved yet. Are you sure you want to close?":
    "Data entered for report creation has not been saved yet. Are you sure you want to close?",
  "Edit Report": "Edit Report",
  "Change History": "Change History",
  "User Activity": "User Activity",
  "System Activity": "System Activity",
  "User activity will be shown here once the reports are created.":
    "User activity will be shown here once the reports are created.",
  "Report runs will be shown here once the reports are created.":
    "Report runs will be shown here once the reports are created.",
  "Are you sure you want to disable this report?": "Are you sure you want to disable this report?",
  "Are you sure you want to enable this report?": "Are you sure you want to enable this report?",
  "Report has been disabled successfully!": "Report has been disabled successfully!",
  "Report has been enabled successfully!": "Report has been enabled successfully!",
  "Error occurred while fetching information.": "Error occurred while fetching information.",
  Monthly: "Monthly",
  "Please note that continuing will reset the selected report time period. Do you wish to proceed?":
    "Please note that continuing will reset the selected report time period. Do you wish to proceed?",
  "Enter valid email to add": "Enter valid email to add",
  "{{name}} Email List": "{{name}} Email List",
  "Enter Email ids where you would like to send generated reports":
    "Enter Email ids where you would like to send generated reports",
  "Report generation is in progress": "Report generation is in progress",
  "Download Latest Report": "Download Latest Report",
  Processing: "Processing",
  "Report was generated.": "Report was generated.",
  "Report was sent to {{count}} email(s).": "Report was sent to {{count}} email(s).",
  "List of Emails ({{listOfMails}})": "List of Emails ({{listOfMails}})",
  "Total package budget can not be 0": "Total package budget can not be 0",
  "Select a date and time": "Select a date and time",
  "Create Report": "Create Report",
  "Delivery End Date": "Delivery End Date",
  "New version available": "New version available",
  "Hey There, we have upgraded our system. Please refresh the app for the seamless experience.":
    "Hey There, we have upgraded our system. Please refresh the app for the seamless experience.",
  "Search Ad Name": "Search Ad Name",
  "Search Report Name": "Search Report Name",
  "Report Active": "Report Active",
  "Report Paused": "Report Paused",
  "Something went wrong. Please try again.": "Something went wrong. Please try again.",
  "Reports scheduled on 29th, 30th or 31st day of the month will only be generated if the month has these dates":
    "Reports scheduled on 29th, 30th or 31st day of the month will only be generated if the month has these dates",
  On: "On",
  "An additional charge added to the unit price for the targeting cluster.":
    "An additional charge added to the unit price for the targeting cluster.",
  "Please select atleast {{min}} {{title}}": "Please select atleast {{min}} {{title}}",
  "Please select a Single {{title}}": "Please select a Single {{title}}",
  "Please select upto {{max}} {{title}}": "Please select upto {{max}} {{title}}",
  "This is your account performance at a glance for the selected date range":
    "This is your account performance at a glance for the selected date range",
  "Last 60 Days": "Last 60 Days",
  "Sorry, we couldn't find any report matching your search term. Please try a different search term.":
    "Sorry, we couldn't find any report matching your search term. Please try a different search term.",
  "Can not edit report since report generation is in progress":
    "Can not edit report since report generation is in progress",
  "Report has been activated successfully!": "Report has been activated successfully!",
  "Report has been paused successfully!": "Report has been paused successfully!",
  "Select Report Type": "Select Report Type",
  "Select Advertiser": "Select Advertiser",
  "All Advertiser": "All Advertiser",
  "Type to Search": "Type to Search",
  "No Advertiser Found": "No Advertiser Found",
  "Show More": "Show More",
  "Show Less": "Show Less",
  "Select the advertising entity based on your access to generate the defined report for the advertising entity.":
    "Select the advertising entity based on your access to generate the defined report for the advertising entity.",
  "Day of month": "Day of month",
  "Performance Trend": "Performance Trend",
  "You can gain vital insights to aid in decision making by comparing your performance on multiple metrics for a customizable date range. The specific dates included in your displayed metrics are shown as the x-axis of the graph.":
    "You can gain vital insights to aid in decision making by comparing your performance on multiple metrics for a customizable date range. The specific dates included in your displayed metrics are shown as the x-axis of the graph.",
  "{{{userName}}} granted {{{access}}} access on {{{clientName}}} to {{{targetUserName}}}":
    "{{{userName}}} granted {{{access}}} access on {{{clientName}}} to {{{targetUserName}}}",
  "{{{userName}}} revoked {{{access}}} access on {{{clientName}}} to {{{targetUserName}}}":
    "{{{userName}}} revoked {{{access}}} access on {{{clientName}}} to {{{targetUserName}}}",
  "{{{userName}}} blocked {{{access}}} access on {{{clientName}}} to {{{targetUserName}}}":
    "{{{userName}}} blocked {{{access}}} access on {{{clientName}}} to {{{targetUserName}}}",
  Dashboard: "Dashboard",
  "Ad Revenue": "Ad Revenue",
  "The revenue generated from the sale of advertising space or time on your platform. This metric measures the success of your advertising program. Keep an eye on ad revenue trends to make informed decisions about future ad sales strategies and investments.":
    "The revenue generated from the sale of advertising space or time on your platform. This metric measures the success of your advertising program. Keep an eye on ad revenue trends to make informed decisions about future ad sales strategies and investments.",
  "Ad Account Performance": "Ad Account Performance",
  "View and analyze the performance metrics of all ad accounts in one consolidated view.":
    "View and analyze the performance metrics of all ad accounts in one consolidated view.",
  "Ad spend is the actual amount spent on serving ads across orders.":
    "Ad spend is the actual amount spent on serving ads across orders.",
  "Campaign Template Performance": "Campaign Template Performance",
  "Analyze the performance of campaign templates across all orders and product templates in a single view.":
    "Analyze the performance of campaign templates across all orders and product templates in a single view.",
  "Product Template Performance": "Product Template Performance",
  "Analyze the performance of product templates across all orders in one consolidated view.":
    "Analyze the performance of product templates across all orders in one consolidated view.",
  "Search Ad Account": "Search Ad Account",
  "Search Campaign Template": "Search Campaign Template",
  "Search Product Template": "Search Product Template",
  "Search Ad": "Search Ad",
  "Search Order": "Search Order",
  "Add {{inputValue}}": "Add {{inputValue}}",
  "Remaining Cost": "Remaining Cost",
  "All Ad Account": "All Ad Account",
  "No Ad Account Found": "No Ad Account Found",
  "Choose specific ad accounts to generate the defined report for a limited set of accounts. By default, the report will be generated for all ad accounts.":
    "Choose specific ad accounts to generate the defined report for a limited set of accounts. By default, the report will be generated for all ad accounts.",
  "Please enter report delivery": "Please enter report delivery",
  "Go to order": "Go to order",
  "Add comments": "Add comments",
  "Add comment": "Add comment",
  "Compare with": "Compare with",
  Compare: "Compare",
  "ORDER | BRAND | PRODUCT TEMPLATE | CHANNEL NAME | CHANNEL ID | PROGRAM NAME | PROGRAM ID | EPISODE NAME | EPISODE ID | AGE | GENDER | DEVICE | AD SPEND | AD IMPRESSION | AD CLICK | CTR | VIDEO 1Q(25%) VIEW | VIDEO 2Q(50%) VIEW | VIDEO 3Q(75%) VIEW | VIDEO COMPLETED VIEW | VTR | VIDEO COMPLETION RATE":
    "ORDER | BRAND | PRODUCT TEMPLATE | CHANNEL NAME | CHANNEL ID | PROGRAM NAME | PROGRAM ID | EPISODE NAME | EPISODE ID | AGE | GENDER | DEVICE | AD SPEND | AD IMPRESSION | AD CLICK | CTR | VIDEO 1Q(25%) VIEW | VIDEO 2Q(50%) VIEW | VIDEO 3Q(75%) VIEW | VIDEO COMPLETED VIEW | VTR | VIDEO COMPLETION RATE",
  "AD | ORDER | BRAND | CHANNEL NAME| CHANNEL ID | PROGRAM NAME | PROGRAM ID | EPISODE NAME | EPISODE ID | AGE | GENDER | DEVICE | AD SPEND | AD IMPRESSION | AD CLICK | CTR | VIDEO 1Q(25%) VIEW | VIDEO 2Q(50%) VIEW | VIDEO 3Q(75%) VIEW | VIDEO COMPLETED VIEW | VTR | VIDEO COMPLETION RATE":
    "AD | ORDER | BRAND | CHANNEL NAME| CHANNEL ID | PROGRAM NAME | PROGRAM ID | EPISODE NAME | EPISODE ID | AGE | GENDER | DEVICE | AD SPEND | AD IMPRESSION | AD CLICK | CTR | VIDEO 1Q(25%) VIEW | VIDEO 2Q(50%) VIEW | VIDEO 3Q(75%) VIEW | VIDEO COMPLETED VIEW | VTR | VIDEO COMPLETION RATE",
  Change: "Change",
  "Change (%)": "Change (%)",
  "Publisher Analytics": "Publisher Analytics",
  "{{length}} {{name}} selected": "{{length}} {{name}} selected",
  "Error occurred while fetching media.": "Error occurred while fetching media.",
  "Ad saved successfully": "Ad saved successfully",
  options: "options",
  "It seems this entry already exists. Please update your name to make it unique.":
    "It seems this entry already exists. Please update your name to make it unique.",
  "Comment added successfully. Please allow a few minutes for the comment notification to be updated.":
    "Comment added successfully. Please allow a few minutes for the comment notification to be updated.",
  "Please enter": "Please enter",
  "You have comments on this order": "You have comments on this order",
  "No comments added yet": "No comments added yet",
  "You can choose schedule only after 30 Mins from now.":
    "You can choose schedule only after 30 Mins from now.",
  "Order archived": "Order archived",
  "Order not active, click to change": "Order not active, click to change",
  "Set daily budget cap": "Set daily budget cap",
  "Daily Budget Cap": "Daily Budget Cap",
  "(Total selected dates {{count}})": "(Total selected dates {{count}})",
  "(Selected dates {{count}})": "(Selected dates {{count}})",
  "Split Equally": "Split Equally",
  "Please enter amount": "Please enter amount",
  "Daily Budget should be less than {{amount}}": "Daily Budget should be less than {{amount}}",
  "Are you sure you want to equally distribute budget across all days?":
    "Are you sure you want to equally distribute budget across all days?",
  "You have budget left to distribute yet.": "You have budget left to distribute yet.",
  "All day's daily budget is not matching with the Total Budget, are you sure you want to proceed?":
    "All day's daily budget is not matching with the Total Budget, are you sure you want to proceed?",
  "Please provide total budget details to set daily schedule.":
    "Please provide total budget details to set daily schedule.",
  "Copy to All": "Copy to All",
  "Copy to Selected": "Copy to Selected",
  "Pulse Report Download": "Pulse Report Download",
  "* Today's data updates can take up to 4 hours to reflect.":
    "* Today's data updates can take up to 4 hours to reflect.",
  "This Week": "This Week",
  Today: "Today",
  "Video 1Q(25%) View": "Video 1Q(25%) View",
  "Impressions of users who watched the first 25% of the video.":
    "Impressions of users who watched the first 25% of the video.",
  "Video 2Q(50%) View": "Video 2Q(50%) View",
  "Impressions of users who watched the second 25% of the video.":
    "Impressions of users who watched the second 25% of the video.",
  "Video 3Q(75%) View": "Video 3Q(75%) View",
  "Impressions of users who watched the third 25% of the video.":
    "Impressions of users who watched the third 25% of the video.",
  "Video Completed View": "Video Completed View",
  "Impression of users who completed views.": "Impression of users who completed views.",
  VTR: "VTR",
  "View Through Rate measures the percentage of impressions that result in view of a video ad.":
    "View Through Rate measures the percentage of impressions that result in view of a video ad.",
  "Video Completion Rate": "Video Completion Rate",
  "The percentage of users who started playing a video and watched it until the end.":
    "The percentage of users who started playing a video and watched it until the end.",
  "You are offline.": "You are offline.",
  "Please check your internet connection!": "Please check your internet connection!",
  Attributes: "Attributes",
  "It seems you don't have access to this dashboard. Contact support for access.":
    "It seems you don't have access to this dashboard. Contact support for access.",
  "It seems you don't have access to this client, or no clients have been onboarded to the platform yet. Contact support for access.":
    "It seems you don't have access to this client, or no clients have been onboarded to the platform yet. Contact support for access.",
  "Bonus rate can not be less than 0": "Bonus rate can not be less than 0",
  "Your daily budgets exceed the total by , please recheck.":
    "Your daily budgets exceed the total by , please recheck.",
  "Add creative details for selected product template, These are the Ads that customer’s will interact with.":
    "Add creative details for selected product template, These are the Ads that customer’s will interact with.",
  "Copy ad from": "Copy ad from",
  "Order Name:": "Order Name:",
  "Package Name:": "Package Name:",
  "Total Ads ({{count}})": "Total Ads ({{count}})",
  "Copy Ad": "Copy Ad",
  "No ad found": "No ad found",
  "From {{time}}": "From {{time}}",
  "Till {{time}}": "Till {{time}}",
  "All Time": "All Time",
  "Creatives not yet added. Add creative details for selected product template.":
    "Creatives not yet added. Add creative details for selected product template.",
  "Please add at least one creative to proceed.": "Please add at least one creative to proceed.",
  "Atleast one creative is required for each product template to activate the order.":
    "Atleast one creative is required for each product template to activate the order.",
  "Duplicate Ad": "Duplicate Ad",
  "Prefilled budget is editable, ensuring it totals 100% per product template":
    "Prefilled budget is editable, ensuring it totals 100% per product template",
  "The ad budget allocation does not add up to 100%. Please fix the distribution.":
    "The ad budget allocation does not add up to 100%. Please fix the distribution.",
  "Budget split must be less than or equal to 100 and greater than 0":
    "Budget split must be less than or equal to 100 and greater than 0",
  "Bugdet Split updated successfully": "Bugdet Split updated successfully",
  "Budget Split": "Budget Split",
  "Enter Budget Split": "Enter Budget Split",
  "Please add budget split": "Please add budget split",
  "Budget split should be maximum 100": "Budget split should be maximum 100",
  "Budget split should be minimum 0": "Budget split should be minimum 0",
  "Budget split for all packages should be 100": "Budget split for all packages should be 100",
  "Order level reach details at your glance to measure the effectiveness of the orders.":
    "Order level reach details at your glance to measure the effectiveness of the orders.",
  "The number of ad impressions the order has received throughout its lifetime.":
    "The number of ad impressions the order has received throughout its lifetime.",
  "The total number of unique viewers to whom the ad has been served throughout the order's lifetime.":
    "The total number of unique viewers to whom the ad has been served throughout the order's lifetime.",
  "New User Acquisition": "New User Acquisition",
  "(Last 7 days)": "(Last 7 days)",
  "Total Reach": "Total Reach",
  "Total Impressions": "Total Impressions",
  "Order Reach Snapshot": "Order Reach Snapshot",
  "By Order": "By Order",
  "By Order & Age": "By Order & Age",
  "By Order & Device": "By Order & Device",
  "By Order & Gender": "By Order & Gender",
  "Advertiser Category": "Advertiser Category",
  "Subscription Category": "Subscription Category",
  "Select Advertiser Category": "Select Advertiser Category",
  "Please select advertiser category": "Please select advertiser category",
  "Select Subscription Category": "Select Subscription Category",
  "Please select subscription category": "Please select subscription category",
  "Download Last Run Report": "Download Last Run Report",
  "Video Creative Library": "Video Creative Library",
  "Unmark as Reviewed": "Unmark as Reviewed",
  "Mark as Reviewed": "Mark as Reviewed",
  Reviewed: "Reviewed",
  "Are you sure you want to mark this video as reviewed? It will be highlighted when reusing the video creative.":
    "Are you sure you want to mark this video as reviewed? It will be highlighted when reusing the video creative.",
  "Are you sure you want to unmark this video as reviewed? You will need to review it again during the order review process.":
    "Are you sure you want to unmark this video as reviewed? You will need to review it again during the order review process.",
  "This creative has already been reviewed.": "This creative has already been reviewed.",
  "Failed to mark creative as reviewed": "Failed to mark creative as reviewed",
  "Creative review status updated": "Creative review status updated",
  "Order Name": "Order Name",
  "Reviewed By": "Reviewed By",
  "Minimum video duration should be 6 seconds.": "Minimum video duration should be 6 seconds.",
  "Select Order(s)": "Select Order(s)",
  "No Order Found": "No Order Found",
  "Select Brand(s)": "Select Brand(s)",
  "All Brands": "All Brands",
  "Modifying the ad accounts will reset your selected orders. Do you wish to continue with this change?":
    "Modifying the ad accounts will reset your selected orders. Do you wish to continue with this change?",
  "All scheduled reports will be generated and delivered by {{time}} am in {{zone}} timezone":
    "All scheduled reports will be generated and delivered by {{time}} am in {{zone}} timezone",
  "Daily Budget should be minimum {{min}}": "Daily Budget should be minimum {{min}}",
  D: "D",
  W: "W",
  M: "M",
  "This is your account performance at a glance for the selected date range,":
    "This is your account performance at a glance for the selected date range,",
  "#NAME?": "#NAME?",
  "Sort by": "Sort by",
  "Something went wrong, All the campaigns could not be loaded. Please try again!":
    "Something went wrong, All the campaigns could not be loaded. Please try again!",
  "Hi {{userName}}, You have {{totalPendingActions}} Actions Pending":
    "Hi {{userName}}, You have {{totalPendingActions}} Actions Pending",
  GMV: "GMV",
  "Create your first campaign to start advertising":
    "Create your first campaign to start advertising",
  "Balance low! Add sufficient amount in your wallet to keep your campaigns running":
    "Balance low! Add sufficient amount in your wallet to keep your campaigns running",
  "Pending Actions": "Pending Actions",
  "Your wallet balance will exhaust soon. Please top-up your wallet to keep your ads running":
    "Your wallet balance will exhaust soon. Please top-up your wallet to keep your ads running",
  "Low Wallet Balance!": "Low Wallet Balance!",
  "Hide Details": "Hide Details",
  "Top Performing Products": "Top Performing Products",
  Prepaid: "Prepaid",
  "Download report for {{start_date}} to {{end_date}}":
    "Download report for {{start_date}} to {{end_date}}",
  "Pending actions for": "Pending actions for",
  "Comfortable View": "Comfortable View",
  "Compact View": "Compact View",
  "Creation Date": "Creation Date",
  "Creation Date1": "Creation Date1",
  Hidden: "Hidden",
  "Select Start Date": "Select Start Date",
  "Select End Date": "Select End Date",
  "No campaign types available": "No campaign types available",
  Categories: "Categories",
  Brands: "Brands",
  Product: "Product",
  "Product Id": "Product Id",
  "30 Day Simulation": "30 Day Simulation",
  "Currently, we are learning about your marketplace dynamics and competition. Shortly, we will provide you with your earning estimates.":
    "Currently, we are learning about your marketplace dynamics and competition. Shortly, we will provide you with your earning estimates.",
  "Added in": "Added in",
  "{{selected_rows}} Products {{txnContent}} Campaign":
    "{{selected_rows}} Products {{txnContent}} Campaign",
  "No Products Available": "No Products Available",
  "Select Individual Product to Include": "Select Individual Product to Include",
  "Select Individual Product to Exclude": "Select Individual Product to Exclude",
  "{{selected_rows}} Products Added in Campaign": "{{selected_rows}} Products Added in Campaign",
  "How it works?": "How it works?",
  "Add Products": "Add Products",
  "{{count}} Products": "{{count}} Products",
  "Product Name": "Product Name",
  "Product Category": "Product Category",
  "Product Brand": "Product Brand",
  Price: "Price",
  "Sale Price": "Sale Price",
  "Product Id equals to": "Product Id equals to",
  "No matching brand found": "No matching brand found",
  "AI optimizes and applies the best bid": "AI optimizes and applies the best bid",
  "Bids are updated regularly for each of your products to improve performance":
    "Bids are updated regularly for each of your products to improve performance",
  "You take control of your Ads by placing bids on a Cost per Click basis":
    "You take control of your Ads by placing bids on a Cost per Click basis",
  "Create your own advertising strategy, monitor your performance and make changes accordingly.":
    "Create your own advertising strategy, monitor your performance and make changes accordingly.",
  "The tool use traffic, competition in the ad auction, sales and other data to estimate how your ads might have performed in terms of key metrics like ad spend, impressions, clicks, and revenue. These estimates do not guarantee similar results.":
    "The tool use traffic, competition in the ad auction, sales and other data to estimate how your ads might have performed in terms of key metrics like ad spend, impressions, clicks, and revenue. These estimates do not guarantee similar results.",
  "Daily Budget is the amount you’ll be spending daily (on average) on your campaign. It is important to note that this amount may not necessarily be spent daily. Daily spend can exceed or recede this value on the basis of selected products, marketplace dynamics and competition.":
    "Daily Budget is the amount you’ll be spending daily (on average) on your campaign. It is important to note that this amount may not necessarily be spent daily. Daily spend can exceed or recede this value on the basis of selected products, marketplace dynamics and competition.",
  "This range is dynamic and can change daily. Actual results may vary.":
    "This range is dynamic and can change daily. Actual results may vary.",
  "See how current campaign settings can impact your ad traffic. This simulation is based on overall data from {{minDate}} - {{maxDate}}.":
    "See how current campaign settings can impact your ad traffic. This simulation is based on overall data from {{minDate}} - {{maxDate}}.",
  "The Max Spend Cap is the maximum value which the campaign can spend overall.":
    "The Max Spend Cap is the maximum value which the campaign can spend overall.",
  "The progress bar indicates how much of your spend cap has been utilized so far. Your campaign will pause once the progress bar reaches the max spend cap limit.":
    "The progress bar indicates how much of your spend cap has been utilized so far. Your campaign will pause once the progress bar reaches the max spend cap limit.",
  "The progress bar indicates how much impressions you have received so far.":
    "The progress bar indicates how much impressions you have received so far.",
  "The progress bar indicates how much of your spend cap has been utilized so far.":
    "The progress bar indicates how much of your spend cap has been utilized so far.",
  "Add the products which you want to promote in the campaign. You can start with your top selling products with good sales. You can also promote a bunch of products which come under the same category.":
    "Add the products which you want to promote in the campaign. You can start with your top selling products with good sales. You can also promote a bunch of products which come under the same category.",
  "Set category level CPCs for each campaign. Here you can choose how much you want to pay for clicks on your ads on the basis of the category your product falls in. An ideal bid is something you should match your bid with to increase your chances of winning the auction.":
    "Set category level CPCs for each campaign. Here you can choose how much you want to pay for clicks on your ads on the basis of the category your product falls in. An ideal bid is something you should match your bid with to increase your chances of winning the auction.",
  "Optimize for Max performance takes the heavy lifting and guesswork out of setting targets to meet your performance goals. System automatically sets the right ROI target for your product ads based on the likelihood to result in a conversion that helps you achieve the maximum possible returns.":
    "Optimize for Max performance takes the heavy lifting and guesswork out of setting targets to meet your performance goals. System automatically sets the right ROI target for your product ads based on the likelihood to result in a conversion that helps you achieve the maximum possible returns.",
  "Set Target ROI": "Set Target ROI",
  "The total charges for clicks across all campaigns. You get charged for the number of clicks you receive on your ads. Charges are incurred when you receive your first click, as well as for every subsequent click.":
    "The total charges for clicks across all campaigns. You get charged for the number of clicks you receive on your ads. Charges are incurred when you receive your first click, as well as for every subsequent click.",
  "The number of times your ad was displayed. An impression occurs whenever an ad is displayed - and this metric shows you a count of how many times your ad has been served to a user. You can view the total number of impressions across all campaigns.":
    "The number of times your ad was displayed. An impression occurs whenever an ad is displayed - and this metric shows you a count of how many times your ad has been served to a user. You can view the total number of impressions across all campaigns.",
  "The total number of times a product was viewed by a shopper after clicking on an ad.":
    "The total number of times a product was viewed by a shopper after clicking on an ad.",
  "When a shopper adds a product to their cart after clicking on an ad before making a purchase.":
    "When a shopper adds a product to their cart after clicking on an ad before making a purchase.",
  "Orders (SKU) refers to the number of unique SKUs as part of orders shoppers placed after clicking on ads. This includes orders from advertised products as well as other products of the same brand (advertised or not).":
    "Orders (SKU) refers to the number of unique SKUs as part of orders shoppers placed after clicking on ads. This includes orders from advertised products as well as other products of the same brand (advertised or not).",
  "The total value of products sold, that are attributed to ads. When shoppers interact with one of your ads and make a purchase of one of your advertised or not advertised products, the sale is attributed to your ad campaign. Only clicks on your ads are eligible for sales attribution, while ad views are not. All sales are reported on the date of the order.":
    "The total value of products sold, that are attributed to ads. When shoppers interact with one of your ads and make a purchase of one of your advertised or not advertised products, the sale is attributed to your ad campaign. Only clicks on your ads are eligible for sales attribution, while ad views are not. All sales are reported on the date of the order.",
  "The ratio of shoppers who click on the ad to the total number of shoppers who have viewed the ad.":
    "The ratio of shoppers who click on the ad to the total number of shoppers who have viewed the ad.",
  "The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend.":
    "The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend.",
  "Amount spent on average for each click shoppers make on your ads.":
    "Amount spent on average for each click shoppers make on your ads.",
  "Campaigns that are programmed to give you greater control over your ads and an increase in reach.":
    "Campaigns that are programmed to give you greater control over your ads and an increase in reach.",
  "Your top performing/more recent campaigns will be shown here, to view their quick details. Each goal gives you access to edit the bids and budgets, select skus and view detailed performance reports. To view the list of all campaigns, click on View all campaigns.":
    "Your top performing/more recent campaigns will be shown here, to view their quick details. Each goal gives you access to edit the bids and budgets, select skus and view detailed performance reports. To view the list of all campaigns, click on View all campaigns.",
  "You can gain vital insights to aid in decision making by comparing your performance on multiple metrics in a week-on-week or month-on-month format - whichever you prefer. Performance Trend gives you the option to view metrics for yesterday, past 7 days, past 30 days, the current month month, the previous month, or for a customizable date range. The specific dates included in your displayed metrics are shown as the x-axis of the graph.":
    "You can gain vital insights to aid in decision making by comparing your performance on multiple metrics in a week-on-week or month-on-month format - whichever you prefer. Performance Trend gives you the option to view metrics for yesterday, past 7 days, past 30 days, the current month month, the previous month, or for a customizable date range. The specific dates included in your displayed metrics are shown as the x-axis of the graph.",
  "View catalog level performance of ads under different products and categories. This provides stats about the top performing products and categories for analysis purposes. You can view performance of a specific SKU by downloading a SKU level analytics report.":
    "View catalog level performance of ads under different products and categories. This provides stats about the top performing products and categories for analysis purposes. You can view performance of a specific SKU by downloading a SKU level analytics report.",
  "Overview of top performing ads against multiple channels":
    "Overview of top performing ads against multiple channels",
  "View performance of ads under different products, categories & keywords. This provides stats about the top performing products, categories & keywords for analysis purposes. You can view performance of a specific SKU by downloading a SKU level analytics report.":
    "View performance of ads under different products, categories & keywords. This provides stats about the top performing products, categories & keywords for analysis purposes. You can view performance of a specific SKU by downloading a SKU level analytics report.",
  "A brief lift-report to show you performance of ads running via this program compared to organic search results. This shows the individual contribution of sponsored ads and organic listings, along with metrics for View Product, Add to Cart, Orders, Ad Revenue, allowing for comparative analysis between the two channels.":
    "A brief lift-report to show you performance of ads running via this program compared to organic search results. This shows the individual contribution of sponsored ads and organic listings, along with metrics for View Product, Add to Cart, Orders, Ad Revenue, allowing for comparative analysis between the two channels.",
  "A brief lift-report to show you performance of ads running via this program compared to other results.This shows the individual contribution of sponsored ads and other listings, along with metrics for View Product, Add to Cart, Orders, Ad Revenue, allowing for comparative analysis between the two channels.":
    "A brief lift-report to show you performance of ads running via this program compared to other results.This shows the individual contribution of sponsored ads and other listings, along with metrics for View Product, Add to Cart, Orders, Ad Revenue, allowing for comparative analysis between the two channels.",
  "You can set bids for each of your product categories. By selecting this option, you are in control of the CPC at category level":
    "You can set bids for each of your product categories. By selecting this option, you are in control of the CPC at category level",
  "The number of times your ad was displayed. An impression occurs whenever an ad is displayed and this metric shows you a count of how many times your ad has been served to a user":
    "The number of times your ad was displayed. An impression occurs whenever an ad is displayed and this metric shows you a count of how many times your ad has been served to a user",
  "The number of clicks on your ads. A click occurs whenever shoppers click on an ad and this metric shows you a count of that":
    "The number of clicks on your ads. A click occurs whenever shoppers click on an ad and this metric shows you a count of that",
  "The percentage of shoppers who see your ad and also click it, calculated as clicks divided by impressions":
    "The percentage of shoppers who see your ad and also click it, calculated as clicks divided by impressions",
  "Suggested Bid": "Suggested Bid",
  Performance: "Performance",
  Spend: "Spend",
  "Search Campaign": "Search Campaign",
  "Create New Campaign": "Create New Campaign",
  "Top Performing Ads": "Top Performing Ads",
  "Your Campaigns are yet to attract visitors. Sit tight and visit this section in a few days.":
    "Your Campaigns are yet to attract visitors. Sit tight and visit this section in a few days.",
  "Performance Summary": "Performance Summary",
  Campaign: "Campaign",
  "Inventory Name": "Inventory Name",
  Impressions: "Impressions",
  Clicks: "Clicks",
  "{{startIndex}}-{{endIndex}} of {{totalPage}}": "{{startIndex}}-{{endIndex}} of {{totalPage}}",
  Showing: "Showing",
  "Row per page": "Row per page",
  "View all {{goalsCount}} campaigns": "View all {{goalsCount}} campaigns",
  "Campaign has been approved by Admin and is active":
    "Campaign has been approved by Admin and is active",
  ROI: "ROI",
  "Program Contribution": "Program Contribution",
  "Smart Shopping Campaigns": "Smart Shopping Campaigns",
  "Search Only Campaigns": "Search Only Campaigns",
  "Download SKU Analytics": "Download SKU Analytics",
  Products: "Products",
  "Change Volume": "Change Volume",
  "Add to Cart": "Add to Cart",
  "Same SKU Orders": "Same SKU Orders",
  "Other SKU Orders": "Other SKU Orders",
  "Your campaign is all set! Check performance periodically for best results":
    "Your campaign is all set! Check performance periodically for best results",
  CPC: "CPC",
  "View Products": "View Products",
  "Sponsored Ads": "Sponsored Ads",
  Organic: "Organic",
  "Optimize your performance with these suggestions.":
    "Optimize your performance with these suggestions.",
  "Order Log": "Order Log",
  Transactions: "Transactions",
  "Wallet balance is the amount available in your program wallet.":
    "Wallet balance is the amount available in your program wallet.",
  "Notification Settings": "Notification Settings",
  "Learn More": "Learn More",
  "Week of __START_OF_WEEK__ to __END_OF_WEEK__": "Week of __START_OF_WEEK__ to __END_OF_WEEK__",
  "How to create and modify a campaign": "How to create and modify a campaign",
  "Goal Settings": "Goal Settings",
  "Step {{count}}: {{title}}": "Step {{count}}: {{title}}",
  "Daily Budget is the amount that you’ll be spending daily (on average) on your campaign. It is important to note that this amount will necessarily not be spent daily. Daily spend can exceed or recede this value.":
    "Daily Budget is the amount that you’ll be spending daily (on average) on your campaign. It is important to note that this amount will necessarily not be spent daily. Daily spend can exceed or recede this value.",
  "Daily Budget:": "Daily Budget:",
  "The figures shown below only take into consideration Native Ads on Marketplace.com and not Marketplace.com external audience newtork ads.":
    "The figures shown below only take into consideration Native Ads on Marketplace.com and not Marketplace.com external audience newtork ads.",
  "Campaign Settings": "Campaign Settings",
  "Product Selection": "Product Selection",
  auto: "auto",
  "{{dataLength}} Ad Placement Selected": "{{dataLength}} Ad Placement Selected",
  "Native ads on __MARKETPLACE_NAME__": "Native ads on __MARKETPLACE_NAME__",
  "Showcase your products intelligently on high-visibility spots on __MARKETPLACE_NAME__ . Highly targeted searches, product pages, category pages and other custom touch points.":
    "Showcase your products intelligently on high-visibility spots on __MARKETPLACE_NAME__ . Highly targeted searches, product pages, category pages and other custom touch points.",
  "__MARKETPLACE_NAME__ Audience Network Ads": "__MARKETPLACE_NAME__ Audience Network Ads",
  "Amplify your reach with marketplace audience network. Get in front of shoppers looking for products you sell!":
    "Amplify your reach with marketplace audience network. Get in front of shoppers looking for products you sell!",
  "With Audience Network Ads, unlock your complete potential in engaging on other channels with your probable customers that have recently visited __MARKETPLACE_NAME__ and have shown interest in your products.":
    "With Audience Network Ads, unlock your complete potential in engaging on other channels with your probable customers that have recently visited __MARKETPLACE_NAME__ and have shown interest in your products.",
  "30 Day Estimates on the Campaign Launch page do not comprise of Audience Network Ads.":
    "30 Day Estimates on the Campaign Launch page do not comprise of Audience Network Ads.",
  "Ad Placement": "Ad Placement",
  "Your average daily budget can be in the range from <currency></currency>{{suggestedMin}} to <currency></currency>{{suggestedMax}}. This is based on your overall data from {{minDate}} - {{maxDate}}.":
    "Your average daily budget can be in the range from <currency></currency>{{suggestedMin}} to <currency></currency>{{suggestedMax}}. This is based on your overall data from {{minDate}} - {{maxDate}}.",
  "Reset to Ideal Settings": "Reset to Ideal Settings",
  "Est. Spend": "Est. Spend",
  "Est. Page Views": "Est. Page Views",
  "Est. Orders": "Est. Orders",
  "Est. Revenue": "Est. Revenue",
  "Est. ROI": "Est. ROI",
  "Current Potential": "Current Potential",
  "Ideal Potential": "Ideal Potential",
  "This will reset all your campaign settings to auto.":
    "This will reset all your campaign settings to auto.",
  "Daily Budget will change to": "Daily Budget will change to",
  "(recommended value)": "(recommended value)",
  "Estimated Spend": "Estimated Spend",
  "Ideal Spend": "Ideal Spend",
  "Product Selection Mode": "Product Selection Mode",
  "SMART MODE": "SMART MODE",
  "AI does all the work": "AI does all the work",
  "24/7 non-stop monitoring": "24/7 non-stop monitoring",
  "Customer driven auto strategies": "Customer driven auto strategies",
  Selected: "Selected",
  "MANUAL MODE": "MANUAL MODE",
  "You do all the work": "You do all the work",
  "You have to constantly monitor products yourself":
    "You have to constantly monitor products yourself",
  "You decide your own strategies": "You decide your own strategies",
  "Manual Product Selection": "Manual Product Selection",
  "Bulk Activity Log": "Bulk Activity Log",
  "How do you want to choose products:": "How do you want to choose products:",
  "Select Products using Filters": "Select Products using Filters",
  "Click on Add Products to select": "Click on Add Products to select",
  "Upload Status Log": "Upload Status Log",
  Timestamp: "Timestamp",
  Comment: "Comment",
  "Error Message": "Error Message",
  "Upload Products in Bulk": "Upload Products in Bulk",
  "Choose Individually": "Choose Individually",
  "Upload Product Selection": "Upload Product Selection",
  "How to upload products in bulk": "How to upload products in bulk",
  "Download Product List": "Download Product List",
  "How to choose products individually": "How to choose products individually",
  Category: "Category",
  Availability: "Availability",
  "No Filters Selected": "No Filters Selected",
  "Product Name contains": "Product Name contains",
  Min: "Min",
  Max: "Max",
  "Estimated Revenue": "Estimated Revenue",
  "Ideal Revenue": "Ideal Revenue",
  "Estimated ROI": "Estimated ROI",
  "Ideal ROI": "Ideal ROI",
  "Estimated Orders": "Estimated Orders",
  "Ideal Orders": "Ideal Orders",
  "Estimated Page Views": "Estimated Page Views",
  "Ideal Page Views": "Ideal Page Views",
  "Estimated Impressions": "Estimated Impressions",
  "Ideal Impressions": "Ideal Impressions",
  "1 Ad Placement Selected": "1 Ad Placement Selected",
  recommended: "recommended",
  "shown against these search terms by placing a higher bid.":
    "shown against these search terms by placing a higher bid.",
  POSITIVE: "POSITIVE",
  "(30 days)": "(30 days)",
  "Match Type": "Match Type",
  "Enter Bid": "Enter Bid",
  "Select where do you want to run your ads": "Select where do you want to run your ads",
  title: "title",
  "Maximum CPC": "Maximum CPC",
  "Add Money to Wallet": "Add Money to Wallet",
  "Top-up Amount:": "Top-up Amount:",
  "Minimum Top-up Amount $250": "Minimum Top-up Amount $250",
  "Proceed to payment": "Proceed to payment",
  "Transaction Date": "Transaction Date",
  Amount: "Amount",
  "Transaction Type": "Transaction Type",
  "Transaction Status": "Transaction Status",
  Accepted: "Accepted",
  "Add incentive as percentage": "Add incentive as percentage",
  incentive: "incentive",
  "{{total}} Reports": "{{total}} Reports",
  "List of reports which are already configured for your account":
    "List of reports which are already configured for your account",
  "Ad Performance Report": "Ad Performance Report",
  "Audience Performance Report": "Audience Performance Report",
  "Brand Lift Report": "Brand Lift Report",
  "Performance by channels (onsite & offsite)": "Performance by channels (onsite & offsite)",
  "Store level Analytics Report": "Store level Analytics Report",
  "SKU Performance Report | Performance Campaigns":
    "SKU Performance Report | Performance Campaigns",
  "Transaction Report": "Transaction Report",
  "Campaign Performance Report | Performance Campaigns":
    "Campaign Performance Report | Performance Campaigns",
  "Data not saved. Are you sure you want to close?":
    "Data not saved. Are you sure you want to close?",
  "SKU Report": "SKU Report",
  "Campaign Performance for selected date range": "Campaign Performance for selected date range",
  Summary: "Summary",
  Carts: "Carts",
  'Click on the "Download Product List". This will download a file containing your current product selection of this campaign.\'':
    'Click on the "Download Product List". This will download a file containing your current product selection of this campaign.\'',
  'Change the product selection by updating "Yes" or "No" value in the file.\'':
    'Change the product selection by updating "Yes" or "No" value in the file.\'',
  "Save the file and upload it back here.": "Save the file and upload it back here.",
  "Note: Only 5000 products can be selected in one campaign.":
    "Note: Only 5000 products can be selected in one campaign.",
  "Products will be automatically added to campaign matching filter condition.":
    "Products will be automatically added to campaign matching filter condition.",
  "Guide to select a product by filters": "Guide to select a product by filters",
  Property: "Property",
  Operator: "Operator",
  "does not contain": "does not contain",
  "Your changes will be lost. Are you sure you want to change?":
    "Your changes will be lost. Are you sure you want to change?",
  "Click on add products to exclude": "Click on add products to exclude",
  Launch: "Launch",
  "Review & Launch": "Review & Launch",
  "row on this page are selected.": "row on this page are selected.",
  "row in table": "row in table",
  "{{count}} Products ({{selected_count}} selected)":
    "{{count}} Products ({{selected_count}} selected)",
  "Product Selections setting updated successfully!":
    "Product Selections setting updated successfully!",
  "{{dataLength}} Products selected": "{{dataLength}} Products selected",
  "10 Products selected": "10 Products selected",
  manual: "manual",
  "Bid Settings": "Bid Settings",
  "Manual bid set across {{dataLength}} categories":
    "Manual bid set across {{dataLength}} categories",
  "Manual bid set across 2 categories": "Manual bid set across 2 categories",
  "Bidding Mode": "Bidding Mode",
  "SMART BIDDING": "SMART BIDDING",
  "AI optimizes and applies the best bid.": "AI optimizes and applies the best bid.",
  "Optimize for Max Performance": "Optimize for Max Performance",
  "You take control of your Ads by placing bids on a Cost per Click basis.":
    "You take control of your Ads by placing bids on a Cost per Click basis.",
  "Manual Bidding": "Manual Bidding",
  "Please enter bid amount": "Please enter bid amount",
  "Enter CPC Bid Values For Categories": "Enter CPC Bid Values For Categories",
  "(By default, system will set min. CPC bid for current & new categories until you change bid manually)":
    "(By default, system will set min. CPC bid for current & new categories until you change bid manually)",
  "Category Name": "Category Name",
  Revenue: "Revenue",
  "Ideal CPC": "Ideal CPC",
  "Bid CPC": "Bid CPC",
  "Apply Bid": "Apply Bid",
  "{{bidCount}} / {{total}} bids set across categories":
    "{{bidCount}} / {{total}} bids set across categories",
  "Apply ROI": "Apply ROI",
  "{{bidValue}} is a conservative bid and will affect your performance.":
    "{{bidValue}} is a conservative bid and will affect your performance.",
  "The ROI target is too low which will affect the campaigns performance. The suggested ROI is {{suggestedBid}}":
    "The ROI target is too low which will affect the campaigns performance. The suggested ROI is {{suggestedBid}}",
  "{{bidValue}} is a superficial bid.": "{{bidValue}} is a superficial bid.",
  "The ROI target is too high and is unlikely to be achieved. The suggested ROI is {{suggestedBid}}":
    "The ROI target is too high and is unlikely to be achieved. The suggested ROI is {{suggestedBid}}",
  "{{bidValue}} is an aggressive bid.": "{{bidValue}} is an aggressive bid.",
  "System will try its best to reach this target.":
    "System will try its best to reach this target.",
  "Your set ROI is not ideal, click to check": "Your set ROI is not ideal, click to check",
  "not ideal": "not ideal",
  "Bid details have been saved successfully": "Bid details have been saved successfully",
  "Are you sure you want to change to Optimized Max Performance mode?":
    "Are you sure you want to change to Optimized Max Performance mode?",
  "Manual bid set across 3 categories": "Manual bid set across 3 categories",
  "You have entered bid for {{bidCount}}/{{total}} categories, the rest will be automatically set to recommended bid.":
    "You have entered bid for {{bidCount}}/{{total}} categories, the rest will be automatically set to recommended bid.",
  "Optimise Impression Share by SKU": "Optimise Impression Share by SKU",
  "No Active Campaigns": "No Active Campaigns",
  "To avoid losing XX% Impressions, you need to include the selected top performing SKUs in your campaigns.":
    "To avoid losing XX% Impressions, you need to include the selected top performing SKUs in your campaigns.",
  "None of your campaigns are active on the platform. Activate your previous campaigns or create a new campaign now to avoid any effect on your performance.":
    "None of your campaigns are active on the platform. Activate your previous campaigns or create a new campaign now to avoid any effect on your performance.",
  "Increase Spend Limit": "Increase Spend Limit",
  "Campaign Spend Limit Alert": "Campaign Spend Limit Alert",
  "Your campaign <bold>__GOAL_NAME__</bold> has reached 80% of maximum spend limit of <bold> <currency /> __MAX_SPEND_CAP__</bold> and will be paused soon. Please increase the max spend limit or remove the limit so that the campaign is not paused which may impact the performance.":
    "Your campaign <bold>__GOAL_NAME__</bold> has reached 80% of maximum spend limit of <bold> <currency /> __MAX_SPEND_CAP__</bold> and will be paused soon. Please increase the max spend limit or remove the limit so that the campaign is not paused which may impact the performance.",
  "Campaign Spend Limit Reached!": "Campaign Spend Limit Reached!",
  "Your campaign <bold>__CAMPAIGN__</bold> has been paused due to Maximum Spend Cap. Please increase your spend cap to activate your campaign or launch a new campaign.":
    "Your campaign <bold>__CAMPAIGN__</bold> has been paused due to Maximum Spend Cap. Please increase your spend cap to activate your campaign or launch a new campaign.",
  "Balance low! Add sufficient amount in your wallet to keep your campaigns running.":
    "Balance low! Add sufficient amount in your wallet to keep your campaigns running.",
  "Congratulations on your First Campaign Launch!":
    "Congratulations on your First Campaign Launch!",
  "Way to go! Your Ads will be live in about 6 hours. Make sure to keep a track on the following:":
    "Way to go! Your Ads will be live in about 6 hours. Make sure to keep a track on the following:",
  "Your campaigns have a healthy daily budget.": "Your campaigns have a healthy daily budget.",
  "Your wallet has sufficient balance and your ads run for a minimum of 14 days to get a good performance from your ad campaigns.":
    "Your wallet has sufficient balance and your ads run for a minimum of 14 days to get a good performance from your ad campaigns.",
  "You monitor your performance regularly and make the required changes to improve your performance.":
    "You monitor your performance regularly and make the required changes to improve your performance.",
  "Performance by": "Performance by",
  "Showing _START_ to _END_ of _TOTAL_ entries": "Showing _START_ to _END_ of _TOTAL_ entries",
  "Add To Carts": "Add To Carts",
  "Brand Id": "Brand Id",
  "Sku Id": "Sku Id",
  Female: "Female",
  Male: "Male",
  "Max Spend Limit:": "Max Spend Limit:",
  "Our AI will automatically select products to advertise. They will change based on seasonal trends and your updated feeds.":
    "Our AI will automatically select products to advertise. They will change based on seasonal trends and your updated feeds.",
  "Top-Up": "Top-Up",
  Unisex: "Unisex",
  "Your Campaign Performance": "Your Campaign Performance",
  "Your Campaigns": "Your Campaigns",
  "Your popular products will be available soon Our platform is gathering data. Please stay tuned and come back in a few hours!":
    "Your popular products will be available soon Our platform is gathering data. Please stay tuned and come back in a few hours!",
  "Amazon Shopping": "Amazon Shopping",
  "App Installs": "App Installs",
  "this Goal": "this Goal",
  "Define Your Budget Settings": "Define Your Budget Settings",
  "Campaign Name": "Campaign Name",
  Day: "Day",
  "Funnel Metrics": "Funnel Metrics",
  Month: "Month",
  Visits: "Visits",
  Week: "Week",
  "View all campaigns": "View all campaigns",
  "Campaign Not Active": "Campaign Not Active",
  "Budget Setting": "Budget Setting",
  "Please enter valid spend limit": "Please enter valid spend limit",
  "Please enter your Goal": "Please enter your Goal",
  "Your spend limit is less than daily budget": "Your spend limit is less than daily budget",
  "Hang tight! We're connecting your store,": "Hang tight! We're connecting your store,",
  "This might take a couple of minutes.": "This might take a couple of minutes.",
  "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).":
    "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
  "Your email id seems to be incorrect. Contact customer support for further assistance.":
    "Your email id seems to be incorrect. Contact customer support for further assistance.",
  "You do not have admin access on the store, please login with admin account":
    "You do not have admin access on the store, please login with admin account",
  "Deep dive into your account performance": "Deep dive into your account performance",
  "Brand Name": "Brand Name",
  "Activate to run ads. Experience AI-driven results":
    "Activate to run ads. Experience AI-driven results",
  "Target CPC": "Target CPC",
  "You can set a maximum spend cap for your campaign. When the max spend limit is reached for a campaign, the ads will be paused till you increase the limit. Not setting a maximum spend cap will allow your campaign to spend based on your daily budget.":
    "You can set a maximum spend cap for your campaign. When the max spend limit is reached for a campaign, the ads will be paused till you increase the limit. Not setting a maximum spend cap will allow your campaign to spend based on your daily budget.",
  "Target ROI": "Target ROI",
  "Campaign Name:": "Campaign Name:",
  "No data available in table": "No data available in table",
  "Number of clicks your ads got through your campaigns":
    "Number of clicks your ads got through your campaigns",
  "The number of times your ads were seen by customers":
    "The number of times your ads were seen by customers",
  "Total amount spent on your campaigns": "Total amount spent on your campaigns",
  "View a quick gist of your overall performance.":
    "View a quick gist of your overall performance.",
  "We’re gathering your performance data. Soon, it will be updated here.":
    "We’re gathering your performance data. Soon, it will be updated here.",
  "We’re keeping a close eye on your ads to find the high performers. We'll share them shortly!":
    "We’re keeping a close eye on your ads to find the high performers. We'll share them shortly!",
  "You will be notified here or via email when your payment is confirmed within 3 days.":
    "You will be notified here or via email when your payment is confirmed within 3 days.",
  "Orders driven by all your campaigns": "Orders driven by all your campaigns",
  "Gross Merchandise Value, which is the revenue driven by all your campaigns":
    "Gross Merchandise Value, which is the revenue driven by all your campaigns",
  "A brief lift-report to show you performance of ads running via this program compared to organic ads":
    "A brief lift-report to show you performance of ads running via this program compared to organic ads",
  "Edit Budget": "Edit Budget",
  "Data Not available": "Data Not available",
  "Min value": "Min value",
  "Projected ROI": "Projected ROI",
  "Projected GMV": "Projected GMV",
  "Projected Ad Impressions": "Projected Ad Impressions",
  "Projected Ad Clicks": "Projected Ad Clicks",
  "Projected Orders": "Projected Orders",
  "Projected Spend": "Projected Spend",
  "Projected Visits": "Projected Visits",
  "Projected Impressions": "Projected Impressions",
  "Projected Revenues": "Projected Revenues",
  "Projected Cart Sessions": "Projected Cart Sessions",
  "Projected CPO": "Projected CPO",
  "Projected ROAS": "Projected ROAS",
  "Type here...": "Type here...",
  "Bid Type:": "Bid Type:",
  "How to choose?": "How to choose?",
  "CPC Bid": "CPC Bid",
  "ROI Target": "ROI Target",
  Configure: "Configure",
  "How this works?": "How this works?",
  "Advanced Bid Settings": "Advanced Bid Settings",
  optional: "optional",
  "Advertising Budget": "Advertising Budget",
  "Top Up": "Top Up",
  "Please enter top-up amount": "Please enter top-up amount",
  "First Page": "First Page",
  "Last Page": "Last Page",
  "{{count}} records": "{{count}} records",
  Pending: "Pending",
  Declined: "Declined",
  Expired: "Expired",
  "Your value is too aggressive": "Your value is too aggressive",
  "Your value is too submissive": "Your value is too submissive",
  "Your value is too submissive, you may not get enough impressions":
    "Your value is too submissive, you may not get enough impressions",
  "Almost there, what can we improve?": "Almost there, what can we improve?",
  "Awesome, what did you like?": "Awesome, what did you like?",
  "Ease of Use": "Ease of Use",
  FeedBack: "FeedBack",
  Other: "Other",
  "Product Features": "Product Features",
  "What went wrong?": "What went wrong?",
  "Write Feedback": "Write Feedback",
  "Your feedback has been submitted successfully": "Your feedback has been submitted successfully",
  "Your opinion matters! This rating will help us improve the product experience.":
    "Your opinion matters! This rating will help us improve the product experience.",
  "based on your campaign performance and daily budget.":
    "based on your campaign performance and daily budget.",
  "Optimise Impression Share by Daily Budget": "Optimise Impression Share by Daily Budget",
  "Optimise Impression Share by Ad Rank": "Optimise Impression Share by Ad Rank",
  "Check your Product Feed, make sure your Feed Hygiene is fixed and all the details are correct":
    "Check your Product Feed, make sure your Feed Hygiene is fixed and all the details are correct",
  "Learn more about how the industry trends affect your impression share loss.":
    "Learn more about how the industry trends affect your impression share loss.",
  "Update your Bid Settings": "Update your Bid Settings",
  "Click to collapse": "Click to collapse",
  "Ad Rank": "Ad Rank",
  "Click to expand": "Click to expand",
  "Created On: {{formattedCreationDate}}": "Created On: {{formattedCreationDate}}",
  "Impression loss by": "Impression loss by",
  "Manually accept these suggestions": "Manually accept these suggestions",
  SKUs: "SKUs",
  Suggestions: "Suggestions",
  "For now, seems like you're in good shape. We will notify you as soons as we have new suggestions.":
    "For now, seems like you're in good shape. We will notify you as soons as we have new suggestions.",
  "We'll notify you as soon as we have new suggestions for you.":
    "We'll notify you as soon as we have new suggestions for you.",
  "You have {{totalPendingCheckCount}} pending actions":
    "You have {{totalPendingCheckCount}} pending actions",
  "Optimize Budget": "Optimize Budget",
  "To avoid losing __PERCENTAGE__% Impressions, please set your daily budget between __BUDGET_RANGE_MINIMUM__ - __BUDGET_RANGE_MAXIMUM__":
    "To avoid losing __PERCENTAGE__% Impressions, please set your daily budget between __BUDGET_RANGE_MINIMUM__ - __BUDGET_RANGE_MAXIMUM__",
  "You have __PERCENTAGE__% fewer Impressions due to a low Daily Budget":
    "You have __PERCENTAGE__% fewer Impressions due to a low Daily Budget",
  "We suggest changing the daily budget between <b>__BUDGET_RANGE_MINIMUM__ - __BUDGET_RANGE_MAXIMUM__":
    "We suggest changing the daily budget between <b>__BUDGET_RANGE_MINIMUM__ - __BUDGET_RANGE_MAXIMUM__",
  "This budget range is based on a bunch of parameters like current industry trends, your bids, product feed and industry benchmarks.":
    "This budget range is based on a bunch of parameters like current industry trends, your bids, product feed and industry benchmarks.",
  "Increasing the daily budget to the suggested value does not guarantee a __PERCENTAGE__% increase in impressions,":
    "Increasing the daily budget to the suggested value does not guarantee a __PERCENTAGE__% increase in impressions,",
  "it is only to guide you to an optimal daily budget based on industry standards.":
    "it is only to guide you to an optimal daily budget based on industry standards.",
  "Causes of a low Ad Rank": "Causes of a low Ad Rank",
  "Fixing these issues does not guarantee a {{percentageValue}}% increase in impressions. It depends on the latest industry trends.":
    "Fixing these issues does not guarantee a {{percentageValue}}% increase in impressions. It depends on the latest industry trends.",
  "If you have too many SKUs that are out of stock, add more! Sold out SKUs don't help anyone":
    "If you have too many SKUs that are out of stock, add more! Sold out SKUs don't help anyone",
  "In order to fix this issue and increase your impressions, we recommend you do the following -":
    "In order to fix this issue and increase your impressions, we recommend you do the following -",
  "Maintain good CPC or ROI bids on your products. Bidding too low result in low volumes":
    "Maintain good CPC or ROI bids on your products. Bidding too low result in low volumes",
  "You have {{percentageValue}}% fewer Impressions due to a low Ad Rank. Your current Ad rank is low as compared to industry standards. This is causing you to lose out of {{percentageValue}}% of impressions for your campaigns.":
    "You have {{percentageValue}}% fewer Impressions due to a low Ad Rank. Your current Ad rank is low as compared to industry standards. This is causing you to lose out of {{percentageValue}}% of impressions for your campaigns.",
  "Impression share loss suggestions are refreshed every 24 hours. If you have made any changes as per the suggestions, please check back for refreshed suggestions":
    "Impression share loss suggestions are refreshed every 24 hours. If you have made any changes as per the suggestions, please check back for refreshed suggestions",
  "The suggestions are generated as per industry trends, so we recommend checking back frequently to remain competitive and increase your impression share.":
    "The suggestions are generated as per industry trends, so we recommend checking back frequently to remain competitive and increase your impression share.",
  "Popular Categories": "Popular Categories",
  "Popular Products": "Popular Products",
  "Revenue driven by all your campaigns": "Revenue driven by all your campaigns",
  "View suggestions that optimise your performance":
    "View suggestions that optimise your performance",
  "Return on Investment, which is the ratio of Ad Revenue and Spend":
    "Return on Investment, which is the ratio of Ad Revenue and Spend",
  "A campaign once archived cannot be unarchived. Are you sure you want to {{passiveName}} {{name}} {{entity}}?":
    "A campaign once archived cannot be unarchived. Are you sure you want to {{passiveName}} {{name}} {{entity}}?",
  Campaigns: "Campaigns",
  "Choose products": "Choose products",
  "Click on select products to add": "Click on select products to add",
  "Please wait until your file is getting processed, we will notify you.":
    "Please wait until your file is getting processed, we will notify you.",
  "Meanwhile you can check status here": "Meanwhile you can check status here",
  "File Uploaded sucessfully. Please check progress in activity log.":
    "File Uploaded sucessfully. Please check progress in activity log.",
  "All Campaigns": "All Campaigns",
  "Product Selected": "Product Selected",
  "Products added in campaign": "Products added in campaign",
  "{{count}} Selected Products": "{{count}} Selected Products",
  "{{count}} Selected Products_plural": "{{count}} Selected Products_plural",
  "Order Log for last 30 days": "Order Log for last 30 days",
  "Order Time": "Order Time",
  "Order Type": "Order Type",
  "Attribution Channel": "Attribution Channel",
  "Enter Daily budget to view your estimated returns over next 30 days":
    "Enter Daily budget to view your estimated returns over next 30 days",
  "Please enter your budget": "Please enter your budget",
  "(You can change these setting on web view)": "(You can change these setting on web view)",
  "Above estimates are calculated by today’s trends and are not guaranteed. It can change daily based on dynamics of the marketplace & competition.":
    "Above estimates are calculated by today’s trends and are not guaranteed. It can change daily based on dynamics of the marketplace & competition.",
  "Your Daily Budget is very less and will affect your campaign’s performance. We recommend {{recommendedBudget}} as your daily budget.":
    "Your Daily Budget is very less and will affect your campaign’s performance. We recommend {{recommendedBudget}} as your daily budget.",
  "We recommend {{recommendedBudget}} as your daily budget considering your campaign Setup.":
    "We recommend {{recommendedBudget}} as your daily budget considering your campaign Setup.",
  "Your Daily Budget is Ideal. You are now ready to launch your campaign.":
    "Your Daily Budget is Ideal. You are now ready to launch your campaign.",
  "Your Daily Budget is very high and will be under utilized. We recommend {{recommendedBudget}} as your daily budget.":
    "Your Daily Budget is very high and will be under utilized. We recommend {{recommendedBudget}} as your daily budget.",
  "Budget should be minimum  {{formattedMinBudget}}":
    "Budget should be minimum  {{formattedMinBudget}}",
  "Please Enter your Daily Budget First to Launch the Campaign.":
    "Please Enter your Daily Budget First to Launch the Campaign.",
  "Internal error has occured while top-up": "Internal error has occured while top-up",
  "Change Name": "Change Name",
  "Yes Continue": "Yes Continue",
  "Are you sure you want to upload file __FILE_NAME__":
    "Are you sure you want to upload file __FILE_NAME__",
  "Selected Products": "Selected Products",
  "Select filters to add products to campaign": "Select filters to add products to campaign",
  "Your ads will be live in 8-10 hours": "Your ads will be live in 8-10 hours",
  "Min. CPC": "Min. CPC",
  "Bids are updated regularly for each of your category":
    "Bids are updated regularly for each of your category",
  "You can set the bids for each of your categories":
    "You can set the bids for each of your categories",
  "By selecting this option, you are in control of CPC at category level":
    "By selecting this option, you are in control of CPC at category level",
  "Choose between Smart Mode(Recommended), where the system smartly selects products for your campaign and Manual Mode, where you do all the work.":
    "Choose between Smart Mode(Recommended), where the system smartly selects products for your campaign and Manual Mode, where you do all the work.",
  "Are you sure you want to change to Smart mode? You will lose your manual product selection and the AI will take over and optimize your product selection automatically.":
    "Are you sure you want to change to Smart mode? You will lose your manual product selection and the AI will take over and optimize your product selection automatically.",
  "Choose between Smart Bidding, where the system places bids for each category and Manual Bidding, where you hold this privilege.":
    "Choose between Smart Bidding, where the system places bids for each category and Manual Bidding, where you hold this privilege.",
  "{{dataLength}} Products excluded": "{{dataLength}} Products excluded",
  "Product Excluded": "Product Excluded",
  "Excluded from": "Excluded from",
  "rows on this table are selected.": "rows on this table are selected.",
  "Clear Selection": "Clear Selection",
  "Are you sure you want to remove selected products from campaign?":
    "Are you sure you want to remove selected products from campaign?",
  "Your changes will be lost. Are you sure you want to close the drawer?":
    "Your changes will be lost. Are you sure you want to close the drawer?",
  "Unable to show goal performance, Please try again later!":
    "Unable to show goal performance, Please try again later!",
  "Setting your Daily budget too low will affect how often your products are shown and affect the performance of your campaign. We suggest you to set your daily budget at {{recommendedBudget}}.":
    "Setting your Daily budget too low will affect how often your products are shown and affect the performance of your campaign. We suggest you to set your daily budget at {{recommendedBudget}}.",
  "Categories Selected": "Categories Selected",
  "Min value {{min}}": "Min value {{min}}",
  "{{bidValue}} is an ideal bid.": "{{bidValue}} is an ideal bid.",
  "Are you sure you want to change to manual bidding?":
    "Are you sure you want to change to manual bidding?",
  "How do you want to bid for the campaign?": "How do you want to bid for the campaign?",
  "Please enter ROI value": "Please enter ROI value",
  "The Daily Budget value is the maximum amount you can spend per day on the campaign.":
    "The Daily Budget value is the maximum amount you can spend per day on the campaign.",
  "Are you sure you want to change to {{bidType}} bid?":
    "Are you sure you want to change to {{bidType}} bid?",
  "Your Ads will only be displayed on search pages. Are you sure you want to change to {{bidType}} bid?":
    "Your Ads will only be displayed on search pages. Are you sure you want to change to {{bidType}} bid?",
  "Loading your products...": "Loading your products...",
  "Balance low! Click to add money in your wallet.":
    "Balance low! Click to add money in your wallet.",
  "Campaign Settings reseted to ideal.": "Campaign Settings reseted to ideal.",
  "Your ads are not running as wallet balance is zero.":
    "Your ads are not running as wallet balance is zero.",
  "Report was sent to __COUNT__ email(s).": "Report was sent to __COUNT__ email(s).",
  "Please enter Report Type": "Please enter Report Type",
  "Performance insights of all your SKUs available for advertising.":
    "Performance insights of all your SKUs available for advertising.",
  "Details of all the transactions undertaken on the Platform.":
    "Details of all the transactions undertaken on the Platform.",
  "Complete overview of all your Performance Campaigns on the Marketplace.":
    "Complete overview of all your Performance Campaigns on the Marketplace.",
  Enabled: "Enabled",
  Disbled: "Disbled",
  "Report has been scheduled successfully!": "Report has been scheduled successfully!",
  "Please enter email ids": "Please enter email ids",
  "The date when you want to stop receiving this report in your Email":
    "The date when you want to stop receiving this report in your Email",
  "All scheduled reports will be generated and delivered by 11 am in your account time zone":
    "All scheduled reports will be generated and delivered by 11 am in your account time zone",
  "Select a delivery timeline for your report": "Select a delivery timeline for your report",
  "Download file for all outlets": "Download file for all outlets",
  "Best practices for creating a campaign": "Best practices for creating a campaign",
  "This is an AI powered feature which gives you 30 day estimated for metrics like Spend, Impressions, Page Views, Orders, Revenue, and ROI on the basis of your current daily budget & current campaign settings.":
    "This is an AI powered feature which gives you 30 day estimated for metrics like Spend, Impressions, Page Views, Orders, Revenue, and ROI on the basis of your current daily budget & current campaign settings.",
  "Created on:": "Created on:",
  "Wallet Balance:": "Wallet Balance:",
  "Minimum Top-up Amount <bold><currency></currency>{{budgetToShow}}</bold>":
    "Minimum Top-up Amount <bold><currency></currency>{{budgetToShow}}</bold>",
  Tax: "Tax",
  "Recommended <bold><currency></currency>{{budgetToShow}}</bold> based on your campaigns.":
    "Recommended <bold><currency></currency>{{budgetToShow}}</bold> based on your campaigns.",
  "Save and Activate": "Save and Activate",
  "No pending actions available at this moment.": "No pending actions available at this moment.",
  "Your ads are stopped due to insufficient wallet balance. Please top up to activate the ads again.":
    "Your ads are stopped due to insufficient wallet balance. Please top up to activate the ads again.",
  "Wallet Exhausted!": "Wallet Exhausted!",
  Wallet: "Wallet",
  "Your wallet balance is exhausted. Please top-up your wallet to keep your ads running.":
    "Your wallet balance is exhausted. Please top-up your wallet to keep your ads running.",
  "Your wallet balance will exhaust soon. Please top-up your wallet to keep your ads running.":
    "Your wallet balance will exhaust soon. Please top-up your wallet to keep your ads running.",
  "Low wallet balance": "Low wallet balance",
  "Incentive Expiry": "Incentive Expiry",
  "View {{goalsCount}} campaigns": "View {{goalsCount}} campaigns",
  "Campaign status cannot be changed once archived":
    "Campaign status cannot be changed once archived",
  "This campaign has been Archived. Any changes made will have no effect on your ads.":
    "This campaign has been Archived. Any changes made will have no effect on your ads.",
  "No campaigns available. Try searching for archived campaigns with the same name from the status section in the filter above.":
    "No campaigns available. Try searching for archived campaigns with the same name from the status section in the filter above.",
  "Invalid clicks and impressions are identified and adjusted every 24 hours on the platform. This can result in different click and impression figures and trends on the dashboard in Live mode and otherwise.":
    "Invalid clicks and impressions are identified and adjusted every 24 hours on the platform. This can result in different click and impression figures and trends on the dashboard in Live mode and otherwise.",
  "Refresh live reporting data": "Refresh live reporting data",
  New: "New",
  "Same day last week": "Same day last week",
  "When a shopper adds a product to their cart after clicking on an ad before making a purchase":
    "When a shopper adds a product to their cart after clicking on an ad before making a purchase",
  "Live reporting data is delayed by {{maxHours}} hours":
    "Live reporting data is delayed by {{maxHours}} hours",
  "The number of clicks on your ads. A click occurs whenever shoppers click on an ad - and this metric shows you a count of that. You can view the total number of clicks received across all campaigns.":
    "The number of clicks on your ads. A click occurs whenever shoppers click on an ad - and this metric shows you a count of that. You can view the total number of clicks received across all campaigns.",
  Awareness: "Awareness",
  "Auction Campaign": "Auction Campaign",
  "Guaranteed Campaign": "Guaranteed Campaign",
  "Sort by:": "Sort by:",
  Inventory: "Inventory",
  "Your campaign is pending approval from Admin": "Your campaign is pending approval from Admin",
  "Congratulations! All your ads have been approved!":
    "Congratulations! All your ads have been approved!",
  Keyword: "Keyword",
  "Smart Keyword Targeting": "Smart Keyword Targeting",
  "AI optimizes and targets the right set of keywords":
    "AI optimizes and targets the right set of keywords",
  "for your products and places the most optimum bid for these keywords.":
    "for your products and places the most optimum bid for these keywords.",
  "Manual Keyword Targeting": "Manual Keyword Targeting",
  "You provide a set of keywords to ensure your products are":
    "You provide a set of keywords to ensure your products are",
  "Your products will continue to be shown for other search":
    "Your products will continue to be shown for other search",
  "terms that are relevant to your products.": "terms that are relevant to your products.",
  "Positive Keyword Targeting": "Positive Keyword Targeting",
  "Negative Keyword Targeting": "Negative Keyword Targeting",
  "{{keywordCount}} Keywords Targeted in Campaign":
    "{{keywordCount}} Keywords Targeted in Campaign",
  "Get Keyword Ideas": "Get Keyword Ideas",
  "You don't have any keywords added to your campaign. Use the 'Get Keyword Ideas' feature to get started with keyword targeting.":
    "You don't have any keywords added to your campaign. Use the 'Get Keyword Ideas' feature to get started with keyword targeting.",
  "Add Keywords": "Add Keywords",
  "Test for Add incentive as percentage": "Test for Add incentive as percentage",
  "You can generate and schedule reports. Create new report by clicking on Create Report":
    "You can generate and schedule reports. Create new report by clicking on Create Report",
  "Your payment was successful. Your ad wallet will be credited in the next 15 mins.":
    "Your payment was successful. Your ad wallet will be credited in the next 15 mins.",
  "Pengaturan budget": "Pengaturan budget",
  "Activate your": "Activate your",
  "Maximum Spend Cap:": "Maximum Spend Cap:",
  "Please select dates": "Please select dates",
  "Let's Get Started": "Let's Get Started",
  "Sign In": "Sign In",
  "Activate Campaign": "Activate Campaign",
  "Campaign Active, Click to Change": "Campaign Active, Click to Change",
  "Campaign Paused, Click to Change": "Campaign Paused, Click to Change",
  "Catalog Performance": "Catalog Performance",
  "Activate your paused campaigns now!": "Activate your paused campaigns now!",
  "File download requested sucessfully. will send you file in mail.":
    "File download requested sucessfully. will send you file in mail.",
  "Campaign Not Active, Click to Archive": "Campaign Not Active, Click to Archive",
  "Campaigns Running:": "Campaigns Running:",
  "You have a balance in your ad wallet which could get you sales. Activate a campaign now.":
    "You have a balance in your ad wallet which could get you sales. Activate a campaign now.",
  "View Details": "View Details",
  "All Products": "All Products",
  "Same Category Orders": "Same Category Orders",
  "Orders (SKU)": "Orders (SKU)",
  "Same Category Ad Revenue": "Same Category Ad Revenue",
  "Same Category ROI": "Same Category ROI",
  "Other Category Orders": "Other Category Orders",
  "Other Category Ad Revenue": "Other Category Ad Revenue",
  "Other Category ROI": "Other Category ROI",
  "Detail Page Views": "Detail Page Views",
  "Keyword Settings": "Keyword Settings",
  "Network Targeting": "Network Targeting",
  "Advance Settings": "Advance Settings",
  "All data is dummy for demo purposes. This data resets in 24 hours":
    "All data is dummy for demo purposes. This data resets in 24 hours",
  Manage: "Manage",
  "Search Campaign Name": "Search Campaign Name",
  "Select Language": "Select Language",
  Events: "Events",
  "© 2017 - {{currentYear}} OSX Techlabs Private Ltd. All rights reserved. Strictly confidential and intended for demo to OnlineSales.ai potential clients only.":
    "© 2017 - {{currentYear}} OSX Techlabs Private Ltd. All rights reserved. Strictly confidential and intended for demo to OnlineSales.ai potential clients only.",
  "Manage Billing": "Manage Billing",
  "Wallet Details": "Wallet Details",
  Balance: "Balance",
  "Promotional Balance": "Promotional Balance",
  "Max usage allowed": "Max usage allowed",
  "Transaction Log": "Transaction Log",
  "Payment Mode": "Payment Mode",
  "Transaction ID": "Transaction ID",
  "Gateway Transaction ID": "Gateway Transaction ID",
  Successful: "Successful",
  Promotional: "Promotional",
  Default: "Default",
  "Flight Date": "Flight Date",
  "Enter Ad Name": "Enter Ad Name",
  "Help Center": "Help Center",
  TARGETING: "TARGETING",
  ADS: "ADS",
  CONFIG: "CONFIG",
  "Set Your Targets": "Set Your Targets",
  "Select your preferred targeting options for this campaign":
    "Select your preferred targeting options for this campaign",
  "Enter details for estimations": "Enter details for estimations",
  "Audience Targeting": "Audience Targeting",
  "Geo Location": "Geo Location",
  "Store Targeting": "Store Targeting",
  "Product Catalog Targeting": "Product Catalog Targeting",
  "Keyword Targeting": "Keyword Targeting",
  "Custom Targeting": "Custom Targeting",
  "Not Configured": "Not Configured",
  "Spin the wheel": "Spin the wheel",
  "Date Range": "Date Range",
  "Campaign priority": "Campaign priority",
  "The total charges for clicks across all campaigns. You get charged for the number of clicks you receive on your ads.":
    "The total charges for clicks across all campaigns. You get charged for the number of clicks you receive on your ads.",
  Impact: "Impact",
  Entity: "Entity",
  Count: "Count",
  System: "System",
  "Detailed Information": "Detailed Information",
  "This refers to the number of orders you've received for the advertised SKU.":
    "This refers to the number of orders you've received for the advertised SKU.",
  "This refers to the number of orders (SKUs) you've received where the purchased SKU was other than the one advertised.":
    "This refers to the number of orders (SKUs) you've received where the purchased SKU was other than the one advertised.",
  "Same SKU Ad Revenue": "Same SKU Ad Revenue",
  "Other SKU Ad Revenue": "Other SKU Ad Revenue",
  "This refers to the total revenue of orders (SKU) of the advertiser product that were sold for this campaign's attributed clicks.":
    "This refers to the total revenue of orders (SKU) of the advertiser product that were sold for this campaign's attributed clicks.",
  "This refers to the total revenue of orders (SKU) of the products other than the advertised product that were sold for this campaign's attributed clicks.":
    "This refers to the total revenue of orders (SKU) of the products other than the advertised product that were sold for this campaign's attributed clicks.",
  "Re-allocation": "Re-allocation",
  "Transfer Out": "Transfer Out",
  "Transfer In": "Transfer In",
  Refund: "Refund",
  "View Mode": "View Mode",
  Internal: "Internal",
  External: "External",
  "Retail Internal": "Retail Internal",
  "The total value of products sold, that are attributed to ads. When shoppers interact with one of your ads and make a purchase of one of your advertised or not advertised products, the sale is attributed to your ad campaign. All sales are reported on the date of the order.":
    "The total value of products sold, that are attributed to ads. When shoppers interact with one of your ads and make a purchase of one of your advertised or not advertised products, the sale is attributed to your ad campaign. All sales are reported on the date of the order.",
  "Consolidated Change History": "Consolidated Change History",
  "Events for": "Events for",
  "Pause due to max cap reached": "Pause due to max cap reached",
  "Paused due to end date reached": "Paused due to end date reached",
  "Choose template and auto create multiple sizes":
    "Choose template and auto create multiple sizes",
  "Use these templates to create your own personalised creatives without any hassle!":
    "Use these templates to create your own personalised creatives without any hassle!",
  "Select Template": "Select Template",
  "Ad Name": "Ad Name",
  "Review ad, campaign and inventory performance on multiple metrics such as spends, impressions, visits, orders and revenue.":
    "Review ad, campaign and inventory performance on multiple metrics such as spends, impressions, visits, orders and revenue.",
  "The remaining funds designated for ad spends within your wallet.":
    "The remaining funds designated for ad spends within your wallet.",
  "The available promotional amount for your wallet, specifically allocated to you as Incentive.":
    "The available promotional amount for your wallet, specifically allocated to you as Incentive.",
  "The maximum net amount permitted for expenditure as ad spends for your wallet.":
    "The maximum net amount permitted for expenditure as ad spends for your wallet.",
  "How it is calculated?": "How it is calculated?",
  "Balance + Promotional Balance - UnBilled Amount":
    "Balance + Promotional Balance - UnBilled Amount",
  Transfer: "Transfer",
  "Create New Wallet": "Create New Wallet",
  "Manage Wallet": "Manage Wallet",
  From: "From",
  To: "To",
  "Enter Amount": "Enter Amount",
  "Select To": "Select To",
  "Maximum Transfer Amount": "Maximum Transfer Amount",
  "Enter Description": "Enter Description",
  "Available wallet balance": "Available wallet balance",
  "© 2017 - 2024 OSX Techlabs Private Ltd. All rights reserved. Strictly confidential and intended for demo to OnlineSales.ai potential clients only.":
    "© 2017 - 2024 OSX Techlabs Private Ltd. All rights reserved. Strictly confidential and intended for demo to OnlineSales.ai potential clients only.",
  "Buying Type": "Buying Type",
  "Creative Rejected": "Creative Rejected",
  "View Creative": "View Creative",
  "Your creative for campaign <bold>__GOAL_NAME__</bold> has been rejected. Make the necessary changes in the creatives by referring to the admin's comment and resubmit your Ads for approval.":
    "Your creative for campaign <bold>__GOAL_NAME__</bold> has been rejected. Make the necessary changes in the creatives by referring to the admin's comment and resubmit your Ads for approval.",
  'You have only __SKU_COUNT__ products selected out of __TOTAL_SKU_COUNT__ for \\"__GOAL_NAME__\\" campaign. Please select __MINIMUM_RANGE_TO_SELECT_PRODUCT__ or more for better results.':
    'You have only __SKU_COUNT__ products selected out of __TOTAL_SKU_COUNT__ for \\"__GOAL_NAME__\\" campaign. Please select __MINIMUM_RANGE_TO_SELECT_PRODUCT__ or more for better results.',
  "Your campaign <bold>__GOAL_NAME__</bold> schedule is <bold> 2 days </bold> away and creative(s) is/are missing":
    "Your campaign <bold>__GOAL_NAME__</bold> schedule is <bold> 2 days </bold> away and creative(s) is/are missing",
  "Audience Targeting is not available for your current selected inventory & page.":
    "Audience Targeting is not available for your current selected inventory & page.",
  "Awareness Ads": "Awareness Ads",
  "Bid Campaign": "Bid Campaign",
  "Creative Missing": "Creative Missing",
  High: "High",
  Medium: "Medium",
  "Offsite Ads": "Offsite Ads",
  "Please be aware that if your wallet balance displays a negative value, it may indicate overspending. This situation may arise if multiple users' activities are attributed to your ad simultaneously, especially when your wallet balance is close to exhaustion.":
    "Please be aware that if your wallet balance displays a negative value, it may indicate overspending. This situation may arise if multiple users' activities are attributed to your ad simultaneously, especially when your wallet balance is close to exhaustion.",
  "Product Catalog Targeting lets you select specific products whose Product Detail Page needs to be targeted with ads.":
    "Product Catalog Targeting lets you select specific products whose Product Detail Page needs to be targeted with ads.",
  "Search Inventory Name": "Search Inventory Name",
  "Target potential customers and deliver personalized ads. You can add multiple audience segments for the campaign.":
    "Target potential customers and deliver personalized ads. You can add multiple audience segments for the campaign.",
  "Swap Wallets": "Swap Wallets",
  "selected for campaign": "selected for campaign",
  Select: "Select",
  Inventories: "Inventories",
  "Page selected": "Page selected",
  "Enter configuration details for estimations": "Enter configuration details for estimations",
  "Media Estimator": "Media Estimator",
  "Delivery so far": "Delivery so far",
  Alpha: "Alpha",
  Page: "Page",
  "All Locations": "All Locations",
  "All Audience": "All Audience",
  "Campaign CPM": "Campaign CPM",
  "Enter here the amount you are willing to pay for a thousand impressions on your Ads.":
    "Enter here the amount you are willing to pay for a thousand impressions on your Ads.",
  "Flexi Budget": "Flexi Budget",
  "Campaign delivery details will be displayed here once the campaign is live.":
    "Campaign delivery details will be displayed here once the campaign is live.",
  "This determines campaign dominance over the other. Please note - Feature is available only to the marketplace team.":
    "This determines campaign dominance over the other. Please note - Feature is available only to the marketplace team.",
  "The amount you are willing to spend on your campaign, on a daily basis.":
    "The amount you are willing to spend on your campaign, on a daily basis.",
  "Starting from step 2, inventories affect targeting and ad format; removal is not supported.":
    "Starting from step 2, inventories affect targeting and ad format; removal is not supported.",
  "Min. CPM bid": "Min. CPM bid",
  "Est. Daily Impressions": "Est. Daily Impressions",
  Tags: "Tags",
  "Min. CPC bid": "Min. CPC bid",
  "Create New": "Create New",
  "Error Occurred while fetching Seller Information.":
    "Error Occurred while fetching Seller Information.",
  "All the updates regarding campaign delivery, and creative reviews will be sent to these Email IDs.":
    "All the updates regarding campaign delivery, and creative reviews will be sent to these Email IDs.",
  "Campaign Alerts Delivery": "Campaign Alerts Delivery",
  "Geo location lets you target upto {{ TARGETING_LIMIT }} locations for your ads. You can either include or exclude locations for your ad targeting":
    "Geo location lets you target upto {{ TARGETING_LIMIT }} locations for your ads. You can either include or exclude locations for your ad targeting",
  "Use the most relevant keywords around your product or brand to capture the interests of the most likely buyers":
    "Use the most relevant keywords around your product or brand to capture the interests of the most likely buyers",
  "Expand the campaign reach by targeting across Marketplace.com networks. Reach the right people, on the right websites, at the right time. Your ads will be shown only if your products are listed on the respective network.":
    "Expand the campaign reach by targeting across Marketplace.com networks. Reach the right people, on the right websites, at the right time. Your ads will be shown only if your products are listed on the respective network.",
  "Geo Location targeting is not available for your current selected inventory & page.":
    "Geo Location targeting is not available for your current selected inventory & page.",
  "The minimum daily budget requirement is {{value}} or more.":
    "The minimum daily budget requirement is {{value}} or more.",
  "Price Priority": "Price Priority",
  "Category Page": "Category Page",
  "({{inventoriesCount}} selected for campaign)": "({{inventoriesCount}} selected for campaign)",
  "{{inventoriesCount}} Inventories": "{{inventoriesCount}} Inventories",
  "Select Product Categories": "Select Product Categories",
  "All Categories": "All Categories",
  "Platform manages your budget by aligning your ad spends with site traffic for optimal utilization.":
    "Platform manages your budget by aligning your ad spends with site traffic for optimal utilization.",
  "Campaign Pacing": "Campaign Pacing",
  "Network targeting is not available for your current selected inventory & page.":
    "Network targeting is not available for your current selected inventory & page.",
  "Estimations provided are valid for the period": "Estimations provided are valid for the period",
  "Included Locations": "Included Locations",
  "Excluded Locations": "Excluded Locations",
  "How to target geo locations": "How to target geo locations",
  "Search locations to exclude": "Search locations to exclude",
  "Search locations to include": "Search locations to include",
  "Keyword Targeting is not available for any of the inventories that you have currently selected. Select an inventory that supports keyword targeting to target custom keywords with your Ads.":
    "Keyword Targeting is not available for any of the inventories that you have currently selected. Select an inventory that supports keyword targeting to target custom keywords with your Ads.",
  "Product Catalog Targeting is not available for your selection of inventories. Select an inventory that supports Merchandise Targeting to target specific product detail pages with your ads.":
    "Product Catalog Targeting is not available for your selection of inventories. Select an inventory that supports Merchandise Targeting to target specific product detail pages with your ads.",
  "Native Ads on Marketplace.com": "Native Ads on Marketplace.com",
  "Marketplace.com Audience Network Ads": "Marketplace.com Audience Network Ads",
  "Ad Under Review": "Ad Under Review",
  "Dynamic Card Banner": "Dynamic Card Banner",
  "Auction Banner Ads": "Auction Banner Ads",
  "Auction Inventory Second Fold": "Auction Inventory Second Fold",
  "DCM Tracker Page": "DCM Tracker Page",
  "Advertise Selected Products": "Advertise Selected Products",
  "Which products do you want to advertise?": "Which products do you want to advertise?",
  "Please add minimum {{validMinCount}} product(s)":
    "Please add minimum {{validMinCount}} product(s)",
  "Upload Ads for the selected inventory position. These are the Ads that customer's will interact with.":
    "Upload Ads for the selected inventory position. These are the Ads that customer's will interact with.",
  "{{inventoryCount}}/{{inventoryAdUnits}} Inventories Eligible":
    "{{inventoryCount}}/{{inventoryAdUnits}} Inventories Eligible",
  "The dates when the campaign ends. Once your campaign ends, they will be paused and no money will be spent on them":
    "The dates when the campaign ends. Once your campaign ends, they will be paused and no money will be spent on them",
  "Product category here refers to the specific category under which the product falls for the activity that you've selected above.":
    "Product category here refers to the specific category under which the product falls for the activity that you've selected above.",
  "Give your target audience a name for identification and to differentiate between different audience segments.":
    "Give your target audience a name for identification and to differentiate between different audience segments.",
  "Audience Name": "Audience Name",
  "Auto Created": "Auto Created",
  "Search audience to add": "Search audience to add",
  "Filtered  Products Campaign": "Filtered  Products Campaign",
  "* Date will be set in the {{timezone}} timezone":
    "* Date will be set in the {{timezone}} timezone",
  "The total charges for impressions across all campaigns. You get charged for the number of impressions you receive on your ads.":
    "The total charges for impressions across all campaigns. You get charged for the number of impressions you receive on your ads.",
  "The total amount to get thousand impressions on your ad":
    "The total amount to get thousand impressions on your ad",
  "View Mode: Internal": "View Mode: Internal",
  "Pending Action": "Pending Action",

  Targeting: "Targeting",
  Config: "Config",
  "Geo location lets you target upto 20 locations for your ads. You can either include or exclude locations for your ad targeting":
    "Geo location lets you target upto 20 locations for your ads. You can either include or exclude locations for your ad targeting",
  "Reach your desired audience by optimizing your campaign for different devices and screen sizes.":
    "Reach your desired audience by optimizing your campaign for different devices and screen sizes.",
  "Device Targeting": "Device Targeting",
  "All Device": "All Device",
  "Network Targeting:": "Network Targeting:",
  "Keyword Targeting:": "Keyword Targeting:",
  "Home Page": "Home Page",
  "Buying Type:": "Buying Type:",
  "all locations": "all locations",
  configured: "configured",
  "all devices": "all devices",
  Reach: "Reach",
  "Too Narrow": "Too Narrow",
  "Too Broad": "Too Broad",
  "Select User Attributes": "Select User Attributes",
  "User attributes is information about users of the platform, their behaviour and purchasing patterns. Create an audience of prospective buyers using this information.":
    "User attributes is information about users of the platform, their behaviour and purchasing patterns. Create an audience of prospective buyers using this information.",
  and: "and",
  "Delivery Address": "Delivery Address",
  "Device type": "Device type",
  "Avg Order Value": "Avg Order Value",
  "Cibil Score": "Cibil Score",
  "Products Purchased Last Month": "Products Purchased Last Month",
  "Premium Plan Duration": "Premium Plan Duration",
  "User Browsing": "User Browsing",
  "Cart Contains": "Cart Contains",
  "Product Discount Percentage": "Product Discount Percentage",
  "User Status": "User Status",
  "Wishlist Contains": "Wishlist Contains",
  "Payment Type": "Payment Type",
  "Maximum Purchase Period": "Maximum Purchase Period",
  "Last Purchase Category": "Last Purchase Category",
  "is any of": "is any of",
  "is none of": "is none of",
  "Keyword Selection": "Keyword Selection",
  "{{keywordCount}} Keywords Added in Campaign": "{{keywordCount}} Keywords Added in Campaign",
  "Number of keywords should be less than {{REMAINING_KEYWORDS}}, keywords above {{REMAINING_KEYWORDS}} will be rejected.":
    "Number of keywords should be less than {{REMAINING_KEYWORDS}}, keywords above {{REMAINING_KEYWORDS}} will be rejected.",
  "Enter keywords": "Enter keywords",
  "The keywords you input for targeting will be considered under Phrase match type.":
    "The keywords you input for targeting will be considered under Phrase match type.",
  "Add Keyword": "Add Keyword",
  "NEW KEYWORDS ADDED ({{keywordCount}})": "NEW KEYWORDS ADDED ({{keywordCount}})",
  "Please add keywords": "Please add keywords",
  "Shoppers will be shown Ads if their search term is matching these keywords.":
    "Shoppers will be shown Ads if their search term is matching these keywords.",
  "Shoppers will see your Ads when their search term matches these keywords and your product offering is relevant to the search term.":
    "Shoppers will see your Ads when their search term matches these keywords and your product offering is relevant to the search term.",
  "Search devices": "Search devices",
  "All Devices": "All Devices",
  iOS: "iOS",
  Android: "Android",
  WEB: "WEB",
  "How to do product catalog targeting": "How to do product catalog targeting",
  "You are targeting ads for:": "You are targeting ads for:",
  "Your ad will be shown on the attributes selected below":
    "Your ad will be shown on the attributes selected below",
  Filters: "Filters",
  "Filter products on the basis of categories, brands etc. that you want to target":
    "Filter products on the basis of categories, brands etc. that you want to target",
  "matching from the selected filters below.": "matching from the selected filters below.",
  Free: "Free",
  "Text Ad": "Text Ad",
  "New Ad": "New Ad",
  "Create Ad": "Create Ad",
  "Your ad Previews": "Your ad Previews",
  "Ad Preview": "Ad Preview",
  Expand: "Expand",
  "Upload Image": "Upload Image",
  Title: "Title",
  "Order Now": "Order Now",
  "Buy Now": "Buy Now",
  "Grab Offer": "Grab Offer",
  "This will reset the merchandise settings, Please confirm to continue.":
    "This will reset the merchandise settings, Please confirm to continue.",
  "Choose Key-Values to target:": "Choose Key-Values to target:",
  "Set custom Campaign CPM for individual inventories":
    "Set custom Campaign CPM for individual inventories",
  Set: "Set",
  "Step 2: Targeting": "Step 2: Targeting",
  "Step 3: Ads": "Step 3: Ads",
  "Step 4: Config": "Step 4: Config",
  "Set custom CPM for Individual Inventories": "Set custom CPM for Individual Inventories",
  "New inventory(s) defaults to this Campaign CPM unless individual CPM is set.":
    "New inventory(s) defaults to this Campaign CPM unless individual CPM is set.",
  "Minimum CPM": "Minimum CPM",
  "Chargeable CPM": "Chargeable CPM",
  "Add Money": "Add Money",
  "Add Money to Your Wallet": "Add Money to Your Wallet",
  "{{pageTypeLabelValue}}": "{{pageTypeLabelValue}}",
  "not configured": "not configured",
  "You have not added any keyword to your campaign yet!":
    "You have not added any keyword to your campaign yet!",
  "Enter the locations you want to target with your ad":
    "Enter the locations you want to target with your ad",
  "Type to search": "Type to search",
  "Add the locations where you don't want your ad to appear":
    "Add the locations where you don't want your ad to appear",
  "How to do audience targeting": "How to do audience targeting",
  "Are you sure you want to reset your selections for this targeting setting?":
    "Are you sure you want to reset your selections for this targeting setting?",
  "Upload .tsv file with details": "Upload .tsv file with details",
  "Ad Approved": "Ad Approved",
  Replicate: "Replicate",
  "Access to this inventory is no longer available for you, however your delivering campaigns will continue as usual.":
    "Access to this inventory is no longer available for you, however your delivering campaigns will continue as usual.",
  "Ad wallet can not be modified once campaign is activated.":
    "Ad wallet can not be modified once campaign is activated.",
  "Life Time Campaign Performance": "Life Time Campaign Performance",
  "Search Inventory": "Search Inventory",
  "{{inventoriesCount}} inventories": "{{inventoriesCount}} inventories",
  "Select Page from dropdown to choose inventory": "Select Page from dropdown to choose inventory",
  "Page Type:": "Page Type:",
  "Select Dates": "Select Dates",
  Dates: "Dates",
  "Some of the days selected in this inventory are unavailable":
    "Some of the days selected in this inventory are unavailable",
  "Pay Later": "Pay Later",
  "Pay Now": "Pay Now",
  "Select Page Type": "Select Page Type",
  "Which page would you like to run the ad on?": "Which page would you like to run the ad on?",
  "Cost Per Day": "Cost Per Day",
  "Inventory Setup": "Inventory Setup",
  "Step 1: Inventory Setup": "Step 1: Inventory Setup",
  "Step 2: Creatives": "Step 2: Creatives",
  "Add More Inventories": "Add More Inventories",
  "Inventories ({{selectedInventories}})": "Inventories ({{selectedInventories}})",
  "Total Cost:": "Total Cost:",
  "Booked Imprs:": "Booked Imprs:",
  "To be Paid:": "To be Paid:",
  CPD: "CPD",
  "Your Inventory slot has been booked by someone else":
    "Your Inventory slot has been booked by someone else",
  "Edit Inventory": "Edit Inventory",
  "Add Negative Keywords": "Add Negative Keywords",
  "<bold>Low Relevancy:</bold> Keyword is either irrelevant or too generic for the advertising products in the campaign.":
    "<bold>Low Relevancy:</bold> Keyword is either irrelevant or too generic for the advertising products in the campaign.",
  "<bold>Low Search Volume:</bold> Keywords with minimal or no search history have a low likelihood of displaying ads until search traffic grows.":
    "<bold>Low Search Volume:</bold> Keywords with minimal or no search history have a low likelihood of displaying ads until search traffic grows.",
  "Keywords with minimal or no search history have a low likelihood of displaying ads until search traffic grows.":
    "Keywords with minimal or no search history have a low likelihood of displaying ads until search traffic grows.",
  "{{targetingNameToUse}} targeting required. Please select {{targetingNameToUse}}(s) to proceed ahead with the campaign.": "{{targetingNameToUse}} targeting required. Please select {{targetingNameToUse}}(s) to proceed ahead with the campaign.",
  "Atleast select one {{targetingName}} to proceed ahead.": "Atleast select one {{targetingName}} to proceed ahead.",
  "{{targetingName}} Targeting saved successfully": "{{targetingName}} Targeting saved successfully",
  "Networks(s)": "Networks(s)",
  "Country(s)": "Country(s)",
  "No Network(s) Available": "No Network(s) Available",
  "No Country(s) Available": "No Country(s) Available",
};

export default {
  translations,
};
