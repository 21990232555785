import React, { useContext, useRef, forwardRef, useEffect, useState } from "react";
import {Trans, useTranslation} from "react-i18next";
import classnames from "classnames";

import { Popover, Overlay } from "react-bootstrap";

import { Button } from "@onlinesales-ai/button-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import "./index.less";

const SelectorContext = React.createContext({});

const CardSelector = forwardRef(
({ selected, children, onCardCTAClick, onChange, showSeprator = true }, ref) => {
  return (
    <SelectorContext.Provider
      value={{
        selected,
        onChange,
        onCardCTAClick,
        showSeprator,
      }}
    >
      <div className="card-selector-container" ref={ref}>{children}</div>
    </SelectorContext.Provider>
  );
});

CardSelector.Card = ({
  cardKey,
  subTypeKey,
  title,
  imageUrl,
  isRecommended,
  description,
  showCta,
  cta,
  subTypes,
  subTypesTitle,
  ctaText,
  noPointerCursor,
  recommendedText,
  recommendedClass,
  containerClass,
  isDisabled,
  containerRef,
  topLeftContent,
  subTitle,
  isShowMainCard = true,
  titleClass,
  selectedIndicatorClass,
  notSelectedIndicatorClass,
  topRightContent,
  cardTooltipTitle,
}) => {
  const {t} = useTranslation();
  const cardSelectorRef = useRef();
  
  const { selected, onCardCTAClick = () => {}, onChange } = useContext(SelectorContext);
  const [ isRefAvailable, setIsRefAvailable ] = useState(false);

  const isSelected = cardKey === selected;

  const notSelected = cardKey !== selected;

  useEffect(() => {
    if(!isRefAvailable && cardSelectorRef.current && containerRef){
      setIsRefAvailable(true);
    }
  }, [cardSelectorRef.current, containerRef]);

  const onClickCta = (subTypeKey) => {
    if (!isDisabled) {
      onChange(cardKey, subTypeKey);
    }
  };

  const getSubTypesDom = () => {
    if(!!subTypes?.length && isSelected) {
      return (
        <div className={`card-selector-subtype ${isShowMainCard ? "with-main-card" : ""}`}>
          <div className="sub-type-wrapper">
            {
              subTypesTitle &&
              <div className="title">
                <Trans>{subTypesTitle}</Trans>
              </div>
            }
            <div className="sub-types-list">
              {
                subTypes?.map(({ imgUrl, title, description, key }) => {
                  const isSelected = key === subTypeKey;

                  return (
                    <div className={`sub-type-card-selector ${isSelected && "selected"}`} onClick={(e) => {
                      e.stopPropagation();
                      onClickCta(key);
                    }}>
                      <div className="top-row-icons">
                        <div className="top-left">
                          {
                            isSelected ?
                              <span className="icon icon-check-circle-2 icon-selected pendo-track-sub-campaign-type-indicator-selected" /> :
                              <span className="icon icon-circle-2 icon-not-selected pendo-track-sub-campaign-type-indicator-not-selected" />
                          }
                        </div>
                      </div>
                      <div className="sub-type-card-selector-inner">
                        <div className="inner-content">
                          {imgUrl && <AsyncImage imgSrc={imgUrl} />}
                          <div className="details">
                            {title && <div className="title"><Trans>{title}</Trans></div>}
                            {description && <div className="description">{typeof description === "string" ? t(description) : description}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  if(!isShowMainCard) {
    return getSubTypesDom();
  }

  return (
    <WithTooltip placement="top" title={cardTooltipTitle}>
      <div
        className={`card-selector ${containerClass} ${noPointerCursor ? "no-pointer-cursor" : ""} ${isSelected ? "selected" : ""} ${
          notSelected ? "not-selected" : ""
        } ${!!subTypes?.length && "has-sub-types"}`}
        ref={cardSelectorRef}
        onClick={() => onClickCta()}
      >
        <div className="top-row-icons">
          <div className="top-left">
            {isSelected && <span className={classnames("icon icon-check-circle-2 icon-selected", selectedIndicatorClass)} />}
            {notSelected && <span className={classnames("icon icon-circle-2 icon-not-selected", notSelectedIndicatorClass)} />}
            {typeof topLeftContent === "function" && topLeftContent()}
          </div>
          <div className="recommended top-right">
            {isRecommended && recommendedText && <div className={`is-recommended ${recommendedClass}`}>{t(recommendedText)}</div>}
          </div>
          {topRightContent ? <>{topRightContent}</> : null}
        </div>
        <div className="card-selector-inner">
          <div className="inner-content">
            {imageUrl && <AsyncImage imgSrc={imageUrl} />}
            <div className="details">
              {title && <div className={classnames("title", titleClass)}><Trans>{title}</Trans></div>}
              {subTitle && <div className="sub-title"><Trans>{subTitle}</Trans></div>}
              {description && <div className="description">{typeof description === "string" ? t(description) : description}</div>}
            </div>
          </div>
          {showCta ? (
            <>
              {cta || (
                <Button disabled={isDisabled} onClick={() => { onCardCTAClick(cardKey); }} className={`card-selector-cta ${isSelected ? "selected" : ""}`}>
                  <Trans>{ctaText || (isSelected ? "Selected" : "Choose")}</Trans>
                </Button>
              )}
            </>
          ) : null}
        </div>
        {getSubTypesDom()}
      </div>
    </WithTooltip>
  )
};

CardSelector.defaultProps = {
  isDisabled: false,
  recommendedText: "recommended",
  subTypesTitle: "",
  containerRef: null,
  topLeftContent: () => {},
};

export default CardSelector;
