export const settingsTabsEnum = {
  SETTINGS_OS_AI_SETUP_CONFIGURATION: {
    key: "SETTINGS_OS_AI_SETUP_CONFIGURATION",
  },
  SELLERS_INFO_CARD: {
    key: "SELLERS_INFO_CARD",
  },
  SETTINGS_SELLER_PROFILE: {
    key: "SETTINGS_SELLER_PROFILE",
  },
  SETTINGS_USER_PROFILE: {
    key: "SETTINGS_USER_PROFILE",
  },
  SETTINGS_ACCESS_MANAGEMENT: {
    key: "SETTINGS_ACCESS_MANAGEMENT",
  },
  SETTINGS_INTEGRATIONS: {
    key: "SETTINGS_INTEGRATIONS",
  },
  SETTINGS_AD_SCHEDULE: {
    key: "SETTINGS_AD_SCHEDULE",
  },
  SETTINGS_AGENT_DETAILS: {
    key: "SETTINGS_AGENT_DETAILS",
  },
  SETTINGS_ACCOUNT_INTEGRATIONS: {
    key: "SETTINGS_ACCOUNT_INTEGRATIONS",
  },
  USER_ACCESS_MANAGEMENT: {
    key: "USER_ACCESS_MANAGEMENT",
  },
};

export const componentIdentifierEnum = {
  CUSTOM_ACCOUNT_LINKING: "CUSTOM_ACCOUNT_LINKING",
  AD_SCHEDULE: "AD_SCHEDULE",
  SELLER_DETAILS: "SELLER_DETAILS",
  USER_PROFILE: "USER_PROFILE",
  ACCOUNT_LINKING: "ACCOUNT_LINKING",
  BUSINESS_DETAILS: "BUSINESS_DETAILS",
  AGENT_DETAILS: "AGENT_DETAILS",
  RMN_INTERGRATION: "RMN_INTERGRATION",
  ACCESS_MANAGEMENT: "ACCESS_MANAGEMENT",
};

export const routes = {
  LOGIN: {
    path: "/login",
  },
  FORGOT_PASSWORD: {
    path: "/forgotPassword",
  },
  CREATE_PASSWORD: {
    path: "/createPassword",
  },
  HARAVAN_CALLBACK: {
    path: "/haravan",
  },
  DASHBOARD: {
    path: "/dashboard",
  },
  EVENTS: {
    path: "/events",
  },
  PACKAGES: {
    path: "/packages",
  },
  BOOKINGS: {
    path: "/bookings",
  },
  CAMPAIGNS: {
    path: "/campaigns",
  },
  DASHBOARD_SMM: {
    path: "/dashboard/smm",
  },
  ONBOARDING: {
    path: "/onboarding",
  },
  DOWNTIME: {
    path: "/downtime",
  },
  SHARAF_MIRAKL_CALLBACK: {
    path: "/sharafMiraklCallback",
  },
  PAYTM_CALLBACK: {
    path: "/paytmCallback",
  },
  PAYTM_BRANDS_CALLBACK: {
    path: "/patymBrandCallback",
  },
  PURPLLE_CALLBACK: {
    path: "/purplleCallback",
  },
  PAYU_CALLBACK: {
    path: "/payuCallback",
  },
  PAYU_PAYMENT_WINDOW_CALLBACK: {
    path: "/payuPaymentWindowCallback",
  },
  TWOC2P_PAYMENT_WINDOW_CALLBACK: {
    path: "/2c2pPaymentWindowCallback",
  },
  KONGA_CALLBACK: {
    path: "/konga_callback",
  },
  TAKEALOT_CALLBACK: {
    path: "/takealotCallback",
  },
  SHOPMATIC_CALLBACK: {
    path: "/shopmaticCallback",
  },
  MRD_CALLBACK: {
    path: "/mrdCallback",
  },
  OS_TOKEN_AUTH_CALLBACK: {
    path: "/osTokenAuthCallback",
  },
  GLOWROAD_CALLBACK: {
    path: "/glowroadCallback",
  },
  SENHENG_CALLBACK: {
    path: "/senhengCallback",
  },
  PAYTM_LANDING_PAGE: {
    path: "/paytm_landing_page",
  },
  PAYTM_BRANDS_LANDING_PAGE: {
    path: "/paytm_brands_landing_page",
  },
  ROUTE_BASED_DRAWER: {
    path: "/drawer",
  },
  ROUTE_BASED_MODAL: {
    path: "/modal",
  },
  ROUTE_BASED_PENDING_ACTION: {
    path: "/pending_action",
  },
  GOOGLE_CALLBACK: {
    path: "/googleCallback",
  },
  SETTINGS: {
    path: "/settings",
  },
  [settingsTabsEnum.SETTINGS_OS_AI_SETUP_CONFIGURATION.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_OS_AI_SETUP_CONFIGURATION.key}`,
  },
  [settingsTabsEnum.SETTINGS_USER_PROFILE.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_USER_PROFILE.key}`,
  },
  [settingsTabsEnum.SETTINGS_SELLER_PROFILE.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_SELLER_PROFILE.key}`,
  },
  [settingsTabsEnum.SETTINGS_INTEGRATIONS.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_INTEGRATIONS.key}`,
  },
  [settingsTabsEnum.SETTINGS_AD_SCHEDULE.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_AD_SCHEDULE.key}`,
  },
  [settingsTabsEnum.SETTINGS_AGENT_DETAILS.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_AGENT_DETAILS.key}`,
  },
  [settingsTabsEnum.SETTINGS_ACCOUNT_INTEGRATIONS.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_ACCOUNT_INTEGRATIONS.key}`,
  },
  [settingsTabsEnum.SETTINGS_ACCESS_MANAGEMENT.key]: {
    path: `/settings/${settingsTabsEnum.SETTINGS_ACCESS_MANAGEMENT.key}`,
  },
  [settingsTabsEnum.USER_ACCESS_MANAGEMENT.key]: {
    path: `/settings/${settingsTabsEnum.USER_ACCESS_MANAGEMENT.key}`,
  },
  OAUTH_CALLBACK: {
    path: "/oauth_callback",
  },
  // EMAIL_CAMPAIGNS: {
  //   path: "/emailCampaigns",
  // },
  SNAPDEAL_CALLBACK: {
    path: "/snapdealCallback",
  },
  OMS_BOOKINGS: {
    path: "/bookings",
  },
  OMS_ANALYTICS: {
    path: "/analytics",
  },
  OMS_VIDEO_CREATIVE_LIBRARY: {
    path: "/creativeLibrary/videoCreativeLibrary",
  },
  OMS_DISPLAY_ADS_CREATIVE_LIBRARY: {
    path: "/creativeLibrary/displayAdsCreativeLibrary",
  },
  REPORTS: {
    path: "/reports",
  },
  GOOGLE_SSO_CALLBACK: {
    path: "/googleSSOCallback",
  },
};
